export default {
  pageTitle: {
    en: "Account Verification",
    ch: "帳戶驗證",
    ja: "アカウントの確認"
  },
  pageSubtitle: {
    en: "Verify your account to have an access to the MMPro services",
    ja: "MMProのサービスを利用するためのアカウント認証について"
  },

  documentsWarning: {
    en: "Please make sure that all the information provided is valid and real-life in accordance with your ID documents.",
    ch: "請確保您提供的所有資訊與您的身份證件一致，是有效且真實的。",
    ja: "提供されたすべての情報が身分証明書に従って有効で実際のものであることを確認してください。",
  },

  editPersonalDataText: {
    en: "Have some problems with your personal data?"
  },

  noChangeWarning: {
    en: "You won’t be able to change it once verified",
    ch: "驗證后您將無法更改它",
    ja: "確認後は変更できません"
  },

  automaticallySave: {
    en: "We automatically save all input data you add in this form.",
    ch: "我們會自動儲存您在此表單中添加的所有輸入數據。",
    ja: "このフォームに追加したすべての入力データは自動的に保存されます。"
  },

  termOfUse: {
    en: "This information is used for KYC only. MMPro keeps confidentially your personal data in accordance with GDPR.",ch: "此資訊僅用於 KYC。MMPro根據GDPR對您的個人數據保密。",
    ja: "この情報は、KYCのためにのみ使用されます。MMProは、GDPRに基づき、お客様の個人データを機密として保持します。"
  },

  buttonTextVerify: {
    en: "Verify personal data",
    ch: "驗證個人數據",
    ja: "個人情報の確認"
  },
  buttonTextCheck: {
    en: "Thank you for submitting your details! We will check them soon.",
    ja: "詳細を送信していただきありがとうございます。 すぐに確認します。"
  },

  accountVerified: {
    en: "Congratulations! You verified your account",
    ja: "おめでとう！ アカウントを確認しました"
  },
  account: {
    en: "Account",
    ch: "帳戶",
    ja: "アカウント"
  },
  verification: {
    en: "Verification",
    ja: "検証"
  },
  verifyAccount: {
    en: "Verify your account to access services on MMPro",
    ja: "MMPro のサービスにアクセスするには、アカウントを確認してください"
  },
  thanksForPassingTitle: {
    en: "Thanks for passing KYC!",
    ja: "KYCに合格していただきありがとうございます!",
    ch: "感謝您通過 KYC！"
  },
  thanksForPassingSubtitle: {
    en: "Your data is being processed and you can already enjoy the features of the application.",
    ja: "あなたのデータは処理されており、あなたはすでにアプリケーションの機能を楽しむことができます",
    ch: "您的資料正在處理中，您已經可以享受該應用程式的功能"
  },
  letsGo: {
    en: "Let's go",
    ja: "行きましょう",
    ch: "我們走吧"
  }
}
