export default {
  learnMore: {
    en: 'Learn more',
    ch: '瞭解更多資訊',
    ja: '詳細情報'
  },
  investButtonText: {
    en: 'Invest',
    ja: '投資する'
  }
}
