export default {
  good: {
    en: "Good",ch: "好",
    ja: "良い"
  },
  notCut: {
    en: "Not cut",ch: "不切割",
    ja: "切らない"
  },
  notBlurry: {
    en: "Not blurry",ch: "不模糊",
    ja: "ぼやけていない"
  },
  notReflective: {
    en: "Not reflective",ch: "不反光",
    ja: "反射しない"
  }
}
