export default {
  "Contact us": {
    en: 'Contact us',
    ja: ''
  },
  "Manage": {
    en: "Manage",
    ja: "管理"
  },
  "Invest": {
    en: "Invest",
    ja: "投資する"
  },
  "Account": {
    en: "Account",ch: "帳戶",
    ja: "アカウント"
  },
  "Verify wallet": {
    en: "Verify wallet",
    ja: "Verify wallet"
  },
  "Personal data": {
    en: "Personal Data",ch: "個人數據",
    ja: "個人データ"
  },
  "Security settings": {
    en: "Security settings",
    ja: "セキュリティ設定"
  },
  "Verify wallets": {
    en: "Verify Wallets",
    ja: "ウォレットの確認"
  },
  "Logout": {
    en: "Logout",ch: "註銷",
    ja: "ログアウト"
  },
  KYCComingSoon: {
    en: "KYC coming soon",
    ja: "近日中にKYC"
  },
  metamaskWalletDisconnectNotificationTitle:{
    en: 'Wallet not disconnected',
    ja: 'ウォレットが切断されていない'
  },
  metamaskWalletDisconnectNotificationSubtitle:{
    en: 'Please use Metamask to disconnect',
    ja: 'メタマスクで接続を解除してください'
  },
  disconnectWallet:{
    en: 'Disconnect wallet',
    ja: 'ウォレットの接続を解除する'
  },

}