import React, {useContext, useEffect, useState} from 'react';
import {Route, Routes, Navigate, useNavigate} from "react-router-dom";
import {RouteName} from './index';
import {useCookies} from "react-cookie";
import Verification from "../pages/Verification";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import Registration from "../pages/Registration";
import Invest from "../pages/Invest";
import Account from "../pages/Account";
import FAQ from "../pages/FAQ";
import ChangeEmail from "../pages/ChangeEmail";
import ChangePassword from "../pages/ChangePassword";
import ChangePhone from "../pages/ChangePhone";
import ChangeCommunicationMethod from "../pages/ChangeCommunicationMethod";
import Deals from "../pages/Deals";
import Manage from "../pages/Manage";
import UserStatusContext from "../context/UserStatusContext";
import VerifyWallets from "../pages/VerifyWallets";
import {useWeb3React} from "@web3-react/core";

const AppRouter = () => {
	const [cookies] = useCookies(['auth']);
	const [isAuth, setIsAuth] = useState<boolean | undefined>(undefined)
	const {isWalletVerified} = useContext(UserStatusContext)

	const history = useNavigate()

	// useEffect(() => {
	// 	if (isWalletVerified && isAuth) {
	// 		history(RouteName.INVEST)
	// 	}
	// }, [isWalletVerified, isAuth]);

	useEffect(() => {
		if (cookies.auth) {
			setIsAuth(true)
		} else {
			setIsAuth(false)
		}
	}, [cookies, cookies.auth])

	return (
		<>
			{(isAuth && isWalletVerified) &&
        <Routes>
          <Route
            element={<Verification/>}
            path={RouteName.VERIFICATION}
          />
          <Route
            element={<VerifyWallets/>}
            path={RouteName.VERIFY_WALLETS}
          />
          <Route
            element={<Invest/>}
            path={RouteName.INVEST}
          />
          <Route
            element={<Account/>}
            path={RouteName.ACCOUNT}
          />
          <Route
            element={<FAQ/>}
            path={RouteName.FAQ}
          />
          <Route
            element={<ChangeEmail/>}
            path={RouteName.CHANGE_EMAIL}
          />
          <Route
            element={<ChangePassword/>}
            path={RouteName.CHANGE_PASSWORD}
          />
          <Route
            element={<ChangePhone/>}
            path={RouteName.CHANGE_PHONE}
          />
          <Route
            element={<ChangeCommunicationMethod/>}
            path={RouteName.CHANGE_COMMUNICATION_METHOD}
          />
          <Route
            element={<Deals/>}
            path={RouteName.ALL_DEALS}
          />
          <Route
            element={<Manage/>}
            path={RouteName.CURRENT_DEAL}
          />
          <Route
            path="*"
            element={<Invest />}
          />
        </Routes>
			}
			{(isAuth && !isWalletVerified) &&
        <Routes>
          <Route
            element={<VerifyWallets/>}
            path={RouteName.VERIFY_WALLETS}
          />
          <Route
            path="*"
            element={<VerifyWallets/>}
          />
        </Routes>
			}
			{isAuth === false &&
        <Routes>
          <Route
            element={<Login/>}
            path={RouteName.LOGIN}
          />
          <Route
            element={<ForgotPassword/>}
            path={RouteName.FORGOT_PASSWORD}
          />
          <Route
            element={<Registration/>}
            path={RouteName.REGISTRATION}
          />
          <Route
            path="*"
            element={<Login/>}
          />
        </Routes>
			}
		</>
	);
};

export default AppRouter;