import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const CustomSelect = styled.div<{ variant: 'large' | 'small' }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${p => (p.variant === 'large' ? 160 : 200)}px;
  height: ${p => (p.variant === 'large' ? 45 : 30)}px;
	padding: 14px;
  border-radius: 4px;
  border: 1px solid rgba(24, 24, 51, 0.10);
  background: #fff;
  cursor: pointer;
`
export const SelectItems = styled.div<{ isActive: boolean; variant: 'large' | 'small' }>`
  position: absolute;
  z-index: 100;
  top: ${p => (p.variant === 'large' ? 53 : 38)}px;
  display: ${p => (p.isActive ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  padding: ${p => (p.variant === 'large' ? 8 : 4)}px;
  width: ${p => (p.variant === 'large' ? 160 : 200)}px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
  max-height: 180px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0; 
    background: transparent;
  }
`
export const SelectItem = styled.div<{ variant: 'large' | 'small' }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  cursor: pointer;
  padding: ${p => (p.variant === 'large' ? '12px' : '6px 8px')};
  min-height: 36px;
  width: ${p => (p.variant === 'large' ? 144 : 192)}px;
  border-radius: 12px;

  &:hover {
    background: #F4F6FB;
  }
`
