import React from "react";

export interface IRoute {
  path: string
  exact?: boolean
  component: () => React.ReactNode
}

export enum RouteName {
  LOGIN = '/',
  REGISTRATION = '/registration/:uuid',
  FORGOT_PASSWORD = '/forgot-password',
  VERIFICATION = '/verification',
  ACCOUNT = '/account',
  FAQ = '/FAQ',
  INVEST = '/invest',
  MANAGE = '/manage',
  CHANGE_EMAIL = '/change-email',
  CHANGE_PASSWORD = '/change-password',
  CHANGE_PHONE = '/change-phone-number',
  CHANGE_COMMUNICATION_METHOD = '/change-communication-method',
  ALL_DEALS = '/deals',
  CURRENT_DEAL = '/deals/:id',
  VERIFY_WALLET = '/verify-wallet',
  VERIFY_WALLETS = '/verify-wallets'
}
