export default {
  repeatNewPassword: {
    en: "Repeat new password",ch: "重複新密碼",ja: "新しいパスワードを再入力する"
  },
  newPassword: {
    en: "New password",ch: "新密碼",ja: "新しいパスワード"
  },
  passwordShouldBeLongerThan8Characters: {
    en: "Password should be longer than 8 characters",ch: "密碼は8文字以上である必要があります",ja: "パスワードは8文字以上である必要があります"
  },
  currentPassword: {
    en: "Current password",ch: "當前密碼",ja: "現在のパスワード"
  },
  changePasswordButton: {
    en: "Change password",ch: "更改密碼",
    ja: "パスワードを変更する"
  },
  passwordLabel: {
    en: "Password",ch: "密碼",
    ja: "パスワード"
  },
  passwordsNotMatchWarning: {
    en: "Passwords should match",ch: "密碼應匹配",
    ja: "パスワードは一致する必要があります"
  }
}
