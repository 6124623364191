export default {
  title: {
    en: "Account",ch: "帳戶",
    ja: "アカウント"
  },
  contactInformation: {
    en: "Contact information",
    ch: "聯繫資訊",
    ja: "連絡先"
  },
  email: {
    en: "Email",ch: "電子郵件",
    ja: "Eメール"
  },
  phone: {
    en: "Phone",ch: "電話",
    ja: "電話"
  },
  communicationMethod: {
    en: "Communication method",ch: "通信方式",
    ja: "通信方法"
  },
  changeButton: {
    en: "Change",ch: "改變",
    ja: "変化する"
  },
  dangerZone: {
    en: "Danger zone",ch: "危險區域",
    ja: "危険区域"
  },
  changePasswordButton: {
    en: "Change password",ch: "更改密碼",
    ja: "パスワードを変更する"
  },
  deleteAccountButton: {
    en: "Delete account",
    ja: "アカウントを削除する"
  }
}
