import React, {useContext, useState} from "react";
import texts from './localization'
import LocaleContext from "Standard/LocaleContext";
import {localized} from "Standard/utils/localized";
import './index.css'
import styled from "styled-components";
import {Row, SpaceBetweenRow, JustifyStartColumn, Column, AlignCenterRow} from "Standard/styles/GlobalStyledComponents";
import Text from "Standard/components/Text";
import TrustButton from "Standard/components/TrustButton";
import ToggleDropdownButton from "icons/ToggleDropdownButton";
import DownloadIcon from "icons/DownloadIcon";
import {IOffer} from "types/Offer";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {API_URL} from "../../api/constants";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import {RouteName} from "../../router";
import UserStatusContext from "../../context/UserStatusContext";

type DealItemPropType = {
	offer: IOffer
}

const DealItemDefaultProps = {}

const DealWrapper = styled(Column)`
  background: #fff;
  width: 100%;
  max-width: 1100px;
  box-shadow: 0 0 27px rgba(94, 103, 120, 0.1);
  margin-bottom: 32px;
  border-radius: 16px;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

const DealHeader = styled(SpaceBetweenRow)`
  padding: 23px;
  border-bottom: 1px solid rgba(24, 24, 51, .1);

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`

const DealImage = styled.img`
  width: 80px;
  height: 40px;
  object-fit: contain;
`

const DealDescriptionItem = styled(JustifyStartColumn)`
  padding-left: 9px;
  border-left: 2px solid #33CC66;
  height: max-content;
  width: 140px;

  @media screen and (max-width: 900px) {
    align-items: flex-start;
  }
`

const ButtonWrapper = styled.div`
  width: 120px;
  margin-left: 30px;

  @media screen and (max-width: 900px) {
    margin-left: 0;
  }
`

const LearnMore = styled(SpaceBetweenRow)`
  padding: 13px 23px;
  cursor: pointer;
`

const AnimatedLearnMoreButton = styled.div<{ isActive: boolean }>`
  transition: all 0.3s;
  transform: ${p => p.isActive ? 'rotate(180deg)' : 'rotate(0deg)'};
  opacity: .3;
`

const CompanyDescriptionWrapper = styled(JustifyStartColumn)<{ isActive: boolean }>`
  transition: all .4s;
  height: ${p => !p.isActive && '0px'};
  transform: ${p => p.isActive ? 'scaleY(1)' : 'scaleY(0)'};
  overflow: ${p => p.isActive ? 'auto' : 'hidden'};
`

const CompanyDescription = styled(JustifyStartColumn)`
  padding: 23px 26px;
  border-bottom: 1px solid rgba(24, 24, 51, .1);
  width: 100%;
`

const CompanyPartners = styled(JustifyStartColumn)`
  padding: 18px 26px;
  border-bottom: 1px solid rgba(24, 24, 51, .1);
  width: 100%;
`

const CompanyPresentation = styled(AlignCenterRow)`
  padding: 21px 23px;
  border-bottom: 1px solid rgba(24, 24, 51, .1);
  width: 100%;
`

const PresentationLink = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #33CC66;

  &:hover {
    color: rgba(51, 204, 102, .6)
  }
`

const TextWrapper = styled.div`
  width: 180px
`

const PointsWrapper = styled(Row)`
  @media screen and (max-width: 700px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const DealItem = (props: DealItemPropType) => {
	const {isInvestmentAllowed} = useContext(UserStatusContext)
	const {locale} = useContext(LocaleContext)
	const {offer} = props

	const history = useNavigate()

	const [cookies] = useCookies(['auth'])

	const [showFullDescription, setShowFullDescription] = useState(false)

	const toggleLearnMoreButton = () => {
		setShowFullDescription(!showFullDescription)
	}

	const createDeal = async () => {
		if (!isInvestmentAllowed) return
		const createDealUrl = `${API_URL}/api/deals/create`

		const requestOptions = {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				"Authorization": cookies.auth
			},
			body: JSON.stringify({
				investmentId: offer.id
			})
		}

		fetch(createDealUrl, requestOptions)
			.then(res => res.json())
			.then(json => {
				if (json.statusCode === 200) {
					history(`${RouteName.ALL_DEALS}/${json.data.deal.dealId}`)
				}
			})
	}

	const goToWebsite = () => {
		window.open(offer.headerTextFourth, '_blank')
	}

	return (
		<DealWrapper>
			<DealHeader>
				<AlignCenterRow gap={9}>
					<DealImage src={`${API_URL}/${offer?.logoPath}`.replaceAll(' ', '%20')}/>
					<TextWrapper>
						<JustifyStartColumn>
							<Text fontWeight={600} fontSize={16}>{offer.name}</Text>
							<Text fontWeight={500} fontSize={12}>{offer.aboutSubtitle}</Text>
						</JustifyStartColumn>
					</TextWrapper>
				</AlignCenterRow>
				<PointsWrapper gap={32}>
					{offer.headerLabelFirst &&
            <DealDescriptionItem>
              <Text fontWeight={500} fontSize={14}>{offer.headerLabelFirst}</Text>
              <Text fontWeight={600} fontSize={16}>{offer.headerTextFirst}</Text>
            </DealDescriptionItem>
					}
					{offer.headerLabelSecond &&
            <DealDescriptionItem>
              <Text fontWeight={500} fontSize={14}>{offer.headerLabelSecond}</Text>
              <Text fontWeight={600} fontSize={16}>{offer.headerTextSecond}</Text>
            </DealDescriptionItem>
					}
					{offer.headerTextThird &&
            <DealDescriptionItem>
              <Text fontWeight={500} fontSize={14}>{offer.headerLabelThird}</Text>
              <Text fontWeight={600} fontSize={16}>{offer.headerTextThird}</Text>
            </DealDescriptionItem>
					}
					{offer.headerLabelFourth &&
            <DealDescriptionItem onClick={goToWebsite}>
              <Text fontWeight={500} fontSize={14}>{offer.headerLabelFourth}</Text>
              <Text fontWeight={600} fontSize={8}>{offer.headerTextFourth}</Text>
            </DealDescriptionItem>
					}
				</PointsWrapper>
				<ButtonWrapper>
					{offer.isActive ?
						<TrustButton style={'green'} isValid onClick={createDeal} isDisabled={!isInvestmentAllowed}>
							<Text fontWeight={600} fontSize={16} color={'#fff'}>{localized(texts.investButtonText, locale)}</Text>
						</TrustButton>
						:
						<Text fontWeight={500} fontSize={16} color={'#33CC66'}>Coming soon</Text>
					}
				</ButtonWrapper>
			</DealHeader>
			<CompanyDescriptionWrapper isActive={showFullDescription}>
				<CompanyDescription>
					<Text
						fontWeight={600}
						fontSize={18}
						adaptiveFontWeight={14}
					>
						About {offer.name}
					</Text>
					<Text
						fontWeight={400}
						fontSize={14}
						adaptiveFontWeight={12}
					>
						{offer.aboutSubtitle}
					</Text>
				</CompanyDescription>
				<CompanyPartners>
					<ReactMarkdown children={offer.description} rehypePlugins={[rehypeRaw]}/>
				</CompanyPartners>
				<CompanyPresentation gap={10}>
					<DownloadIcon/>
					<PresentationLink href={`${API_URL}/${offer?.presentationPath}`.replaceAll(' ', '%20')}
					                  target="_blank">{offer.presentationLabel}</PresentationLink>
				</CompanyPresentation>
			</CompanyDescriptionWrapper>
			<LearnMore onClick={toggleLearnMoreButton}>
				<Text fontWeight={500} fontSize={14}
				      color={'rgba(24, 24, 51, .3)'}>{localized(texts['learnMore'], locale)}</Text>
				<AnimatedLearnMoreButton isActive={showFullDescription}>
					<ToggleDropdownButton/>
				</AnimatedLearnMoreButton>
			</LearnMore>
		</DealWrapper>
	)
};

DealItem.defaultProps = DealItemDefaultProps

export default DealItem
