export default {
  repeatNewPassword: {
    en: "Repeat new password",ch: "重複新密碼",ja: "新しいパスワードを再入力する"
  },
  updatePassword: {
    en: "Update password",ch: "更新密碼",ja: "パスワードの更新"
  },
  newPassword: {
    en: "New password",ch: "新密碼",ja: "新しいパスワード"
  },
  passwordShouldBeLongerThan8Characters: {
    en: "8 characters required",ch: "密碼は8文字以上である必要があります",ja: "8文字必要です"
  },
  currentPassword: {
    en: "Current password",ch: "當前密碼",ja: "現在のパスワード"
  },
  changePasswordButton: {
    en: "Change password",ch: "更改密碼",
    ja: "パスワードを変更する"
  },
  passwordLabel: {
    en: "Password",ch: "密碼",
    ja: "パスワード"
  },
  passwordsNotMatchWarning: {
    en: "Passwords should match",ch: "密碼應匹配",
    ja: "パスワードは一致する必要があります"
  },
  emailAddressLabel: {
    en: "Email",ch: "電子郵件",
    ja: "電子メールアドレス"
  },
  forgotPassword: {
    en: "Forgot password?",ch: "忘記密碼",
    ja: "パスワードをお忘れですか？"
  },
  incorrectEmail: {
    en: "Incorrect email ",ch: "不正な電子郵件",
    ja: "無効な電子メール"
  }
}
