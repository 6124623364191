import React, {useContext} from 'react';
import StepItem from "Standard/components/Stepper/StepItem";
import {AlignCenterRow, JustifyStartColumn,} from "Standard/styles/GlobalStyledComponents";
import Text from "Standard/components/Text";
import {IDealActions, IDealStepStatus} from "types/ManageStatus";
import {localized} from "../../../Standard/utils/localized";
import texts from './localization'
import LocaleContext from "../../../Standard/LocaleContext";

type SignDocumentsProps = {
  status: IDealStepStatus,
  action: IDealActions
}

const DealConfirmation = (props: SignDocumentsProps) => {
  const {status, action} = props
  const {locale} = useContext(LocaleContext)
  return (
    <StepItem status={status} isLastStep>
      <JustifyStartColumn>
        <Text fontWeight={600} fontSize={24}>{localized(texts.waitDealConfirm, locale)}</Text>
      </JustifyStartColumn>
      <div className={'mb-4'} />
    </StepItem>
  );
};

export default DealConfirmation;
