export default {
	configure: {
		en: "Deal size",
		ja: "取引サイズ",
		ch: "配置初始交易"
	},
	checkAmount: {
		en: "To continue please check amount and select communication method.",
		ja: "続行するには、金額を確認し、通信方法を選択してください。",
		ch: "要繼續，請檢查金額並選擇通信方式。"
	},
	getStarted: {
		en: "Please specify the deposit amount, not less than $100",
		ja: "入金額を指定してください、less100未満ではありません",
		ch: "首先，輸入金額並選擇通訊方式。發送數據後，管理員將與您聯繫以確認信息。"
	},
	fromManager: {
		en: "Message from manager:",
		ja: "マネージャーからのメッセージ:",
		ch: "經理寄語："
	},
	amountIn: {
		en: "Amount in $",
		ja: "$での金額",
		ch: "金額（美元）"
	},
	confirm: {
		en: "Confirm the start of the deal",
		ja: "取引の開始を確認する",
		ch: "確認"
	},
	noContact: {
		en: "You didn't provide contacts. Follow the link and edit",
		ja: "連絡先を提供しませんでし",
		ch: "您沒有提供連絡人。點擊連結並編輯"
	},
	submitted: {
		en: "You have successfully submitted your application.",
		ja: "申請が正常に提出されました。",
		ch: "您已成功提交申請。"
	},
	waitResponse: {
		en: "Wait for a response from the manager.",
		ja: "マネージャーからの応答を待ちます。",
		ch: "等待經理的回復。"
	},
	yourContacts: {
		en: "Your contacts:",
		ja: "あなたの連絡先:",
		ch: "您的聯繫人："
	}
}
