import {localized} from "../../Standard/utils/localized";

export default {
  success: {
    en: "Success",
    ch: "成功",
    ja: "成功",
  },
  youSuccessfullyUpdateYourPersonalData: {
    en: "You successfully updated your personal data",
    ch: "您已成功發送報告消息",
    ja: "レポート メッセージを正常に送信しました",
  },
  communicationMethod: {
    en: "Communication method",ch: "通信方式",
    ja: "通信方法"
  },
  invalidCommunicationMethod: {
    en: "Invalid communication method",ch: "无效的通信方式",
    ja: "無效的通信方式"
  },
  writeYour: {
    en: "Write your",ch: "写你的",
    ja: "あなたの"
  },
  contacts: {
    en: "Contacts",ch: "联系人",
    ja: "連絡先"
  },
  changeMethod: {
    en: "Change method",ch: "更改方法",
    ja: "変化"
  },
  communicationMethodChangeError: {
    en: "Communication method change error",ch: "通信方式変更に失敗しました",
    ja: "通信方式変更エラー",
  }
}
