import React, {useContext, useEffect, useState} from "react";
import texts from './localization'
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import styled from "styled-components";
import SubHeader from "../../components/SubHeader";
import ManageBackground from "../../icons/ManageBackground";
import IosStyleSegmentedControll from "../../Standard/components/IosStyleSegmentedControll";
import {
	JustifyStartColumn,
	StartRow,
	JustifyCenterColumn,
} from "../../Standard/styles/GlobalStyledComponents";
import Text from "../../Standard/components/Text";
import {useNavigate} from "react-router-dom";
import {RouteName} from "../../router";
import {API_URL} from "../../api/constants";
import {useCookies} from "react-cookie";
import {IDeal, StageToTitleMapping} from "../../types/ManageStatus";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import Spinner from "../../Standard/components/Spinner";

type DealsPropType = {}

const AllDealsDefaultProps = {}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 36px 14%;
  width: 100%;
  z-index: 1;
`

const DealDescriptionItem = styled(JustifyStartColumn)`
  padding-left: 9px;
  border-left: 2px solid #33CC66;
  height: max-content;
	width: 30%;
	
	@media screen and (max-width: 900px) {
		align-items: flex-start;
		width: 100%;
	}
`

const DealsPanelWrapper = styled(JustifyCenterColumn)`
  //max-width: 1200px;
`

const CurrentDeal = styled(StartRow)`
  background: #fff;
  box-shadow: 0 0 27px rgba(94, 103, 120, 0.1);
  border-radius: 16px;
  min-width: 300px;
  width: 700px;
  z-index: 10;
  margin-bottom: 16px;
  margin-right: 16px;
  cursor: pointer;
	
	@media screen and (max-width: 730px) {
		width: 100%;
	}
`

const DealImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 16px;
`

const DealDescription = styled(StartRow)`
	width: 100%;
  padding: 27px 30px;
	
	@media screen and (max-width: 900px) {
		display: flex;
		flex-direction: column;
	}
`

const ImageWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 9px;
  width: 30%;
	
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

const AllDeals = (props: DealsPropType) => {
	const {locale} = useContext(LocaleContext)

	const [activeButton, setActiveButton] = useState<number>(0)
	const [allActiveDeals, setAllActiveDeals] = useState<IDeal[] | undefined>(undefined)
	const [allClosedDeals, setAllClosedDeals] = useState<IDeal[] | undefined>(undefined)

	const [isDealsLoading, setIsDealsLoading] = useState(false)

	const buttons = [`${localized(texts.active, locale)} (${allActiveDeals ? allActiveDeals.length : 0})`, `${localized(texts.closed, locale)} (${allClosedDeals ? allClosedDeals.length : 0})`]

	const [cookies] = useCookies(['auth'])
	const history = useNavigate()

	const getAllDeals = async () => {
		setIsDealsLoading(true)
		const allDealsUrl = `${API_URL}/api/deals`

		const requestOptions = {
			method: 'GET',
			headers: {
				"Content-Type": "application/json",
				"Authorization": cookies.auth
			},
		}

		fetch(allDealsUrl, requestOptions)
			.then(res => res.json())
			.then(json => {
				if (json.statusCode === 200) {
					const activeDeals = json.data.deals.filter((deal: IDeal) => deal.stage !== 'closed')
					const closedDeals = json.data.deals.filter((deal: IDeal) => deal.stage === 'closed')
					setAllActiveDeals(activeDeals)
					setAllClosedDeals(closedDeals)
					setIsDealsLoading(false)
				}
			})
			.finally(() => setIsDealsLoading(false))
	}

	useEffect(() => {
		getAllDeals()
	}, [])

	return (
		<Container>
			<SubHeader
				backgroundIcon={<ManageBackground/>}
				greenTitle={localized(texts.manage, locale)}
			/>
			<DealsPanelWrapper>
				<IosStyleSegmentedControll
					width={350} buttons={buttons}
					firstSelectedIndex={activeButton}
					onChange={setActiveButton}
				/>
				<div className={'mb-10'}/>
				<JustifyCenterColumn>
					{
						activeButton === 0 ?
							<>
								{
									!isDealsLoading ?
										<div style={{width: '100%'}}>
											{
												allActiveDeals && allActiveDeals.length ?
													(
														allActiveDeals.map(deal =>
															<CurrentDeal
																key={deal.dealId}
																gap={9}
																onClick={() => history(`${RouteName.ALL_DEALS}/${deal.dealId}`)}
															>
																<DealDescription gap={9}>
																	<ImageWrapper>
																		<DealImage
																			src={`${API_URL}/${deal.investment?.logoPath}`.replaceAll(' ', '%20')}/>
																		<JustifyStartColumn>
																			<Text fontWeight={600} fontSize={16}>{deal.investment?.name}</Text>
																			<ReactMarkdown children={deal.investment?.aboutSubtitle}
																			               rehypePlugins={[rehypeRaw]}/>
																		</JustifyStartColumn>
																	</ImageWrapper>
																	<div className={'mr-6'}/>
																	<DealDescriptionItem>
																		<Text fontWeight={500} fontSize={14}>{localized(texts.status, locale)}</Text>
																		<Text fontWeight={600} fontSize={16}>{StageToTitleMapping[`${deal.stage}`]}</Text>
																	</DealDescriptionItem>
																	<DealDescriptionItem>
																		<Text fontWeight={500} fontSize={14}>{localized(texts.invested, locale)}</Text>
																		{deal.stage === 'closed' && <Text fontWeight={600} fontSize={16}>${deal.fundsDeposited}</Text>}
																	</DealDescriptionItem>
																</DealDescription>
															</CurrentDeal>
														))
													:
													<Text fontWeight={500} fontSize={16}>{localized(texts.noActiveDeals, locale)}</Text>
											}
										</div>
										:
										<Spinner size={25} color={'#33CC66'}/>
								}
							</>
							:
							<>
								{
									!isDealsLoading ?
										<>
											{
												allClosedDeals && allClosedDeals.length ?
													<>
														{
															allClosedDeals.map(deal =>
																<CurrentDeal
																	key={deal.dealId}
																	gap={9}
																	onClick={() => history(`${RouteName.ALL_DEALS}/${deal.dealId}`)}
																>
																	<DealDescription gap={9}>
																		<ImageWrapper>
																			<DealImage
																				src={`${API_URL}/${deal.investment?.logoPath}`.replaceAll(' ', '%20')}/>
																			<JustifyStartColumn>
																				<Text fontWeight={600} fontSize={16}>{deal.investment?.name}</Text>
																				<ReactMarkdown children={deal.investment?.aboutSubtitle}
																				               rehypePlugins={[rehypeRaw]}/>
																			</JustifyStartColumn>
																		</ImageWrapper>
																		<div className={'mr-6'}/>
																		<DealDescriptionItem>
																			<Text fontWeight={500} fontSize={14}>{localized(texts.status, locale)}</Text>
																			<Text fontWeight={600} fontSize={16}>{StageToTitleMapping[`${deal.stage}`]}</Text>
																		</DealDescriptionItem>
																		<DealDescriptionItem>
																			<Text fontWeight={500} fontSize={14}>{localized(texts.invested, locale)}</Text>
																			{deal.fundsDeposited && <Text fontWeight={600} fontSize={16}>${deal.fundsDeposited}</Text>}
																		</DealDescriptionItem>
																	</DealDescription>
																</CurrentDeal>
															)}
													</>
													:
													<Text fontWeight={500} fontSize={16}>{localized(texts.noClosedDeals, locale)}</Text>
											}
										</>
										:
										<Spinner size={25} color={'#33CC66'}/>
								}
							</>
					}
				</JustifyCenterColumn>
			</DealsPanelWrapper>
		</Container>
	)
};

AllDeals.defaultProps = AllDealsDefaultProps

export default AllDeals

