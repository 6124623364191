export default {
	passport: {
		en: "Passport",
		ja: "パスポート",
		ch: "護照"
	},
	seriesAndNumber: {
		en: "Series and Number",
		ja: "シリーズと番号",
		ch: "系列和編號"
	}
}
