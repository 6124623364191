import React from 'react';

// Import colors from constants or use currentColor
// If icon uses more than one adjustable color you can pass colors from props
// BUT you must always provide default values for color props

export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 14.6667H12V13.3333C12 12.8029 11.7892 12.2942 11.4142 11.9191C11.0391 11.544 10.5304 11.3333 9.99996 11.3333H5.99996C5.46953 11.3333 4.96082 11.544 4.58575 11.9191C4.21067 12.2942 3.99996 12.8029 3.99996 13.3333V14.6667H2.66663V13.3333C2.66663 12.4493 3.01782 11.6014 3.64294 10.9763C4.26806 10.3512 5.1159 9.99999 5.99996 9.99999H9.99996C10.884 9.99999 11.7319 10.3512 12.357 10.9763C12.9821 11.6014 13.3333 12.4493 13.3333 13.3333V14.6667ZM7.99996 8.66666C7.47467 8.66666 6.95453 8.56319 6.46923 8.36217C5.98392 8.16116 5.54297 7.86652 5.17153 7.49508C4.8001 7.12365 4.50546 6.68269 4.30444 6.19739C4.10342 5.71209 3.99996 5.19194 3.99996 4.66666C3.99996 4.14137 4.10342 3.62123 4.30444 3.13592C4.50546 2.65062 4.8001 2.20966 5.17153 1.83823C5.54297 1.4668 5.98392 1.17216 6.46923 0.971138C6.95453 0.77012 7.47467 0.666656 7.99996 0.666656C9.06083 0.666657 10.0782 1.08808 10.8284 1.83823C11.5785 2.58837 12 3.60579 12 4.66666C12 5.72752 11.5785 6.74494 10.8284 7.49508C10.0782 8.24523 9.06083 8.66666 7.99996 8.66666V8.66666ZM7.99996 7.33332C8.7072 7.33332 9.38548 7.05237 9.88558 6.55227C10.3857 6.05218 10.6666 5.3739 10.6666 4.66666C10.6666 3.95941 10.3857 3.28114 9.88558 2.78104C9.38548 2.28094 8.7072 1.99999 7.99996 1.99999C7.29272 1.99999 6.61444 2.28094 6.11434 2.78104C5.61424 3.28114 5.33329 3.95941 5.33329 4.66666C5.33329 5.3739 5.61424 6.05218 6.11434 6.55227C6.61444 7.05237 7.29272 7.33332 7.99996 7.33332V7.33332Z"
        fill="black"/>
    </svg>
  );
};
