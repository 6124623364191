export default {
  ifYourPersonalDataChangesPleaseUpdateItHere: {
    en: 'If your personal data changes please update it here',
    ja: '個人データが変更された場合は、ここで更新してください',
    ch: '如果您的個人數據發生變化，請在此處更新。'
  },
  tileTitle: {
    en: "Residence",ch: "住宅",
    ja: "住居"
  },

  residenceLabel: {
    en: "Residence",
    ja: "住居"
  },
  cityLabel: {
    en: "City",ch: "城市",
    ja: "街"
  },
  postalCodeLabel: {
    en: "Postal code",ch: "郵遞區號",
    ja: "郵便番号"
  },
  mainAddressLabel: {
    en: "Address",ch: "位址",
    ja: "住所№1"
  },
  additionalAddressLabel: {
    en: "Address №2",
    ja: "住所№2"
  },
  region: {
    en: "Region",ch: "地區",
    ja: "地域"
  },
  nationalityLabel: {
    en: "Nationality",ch: "國籍",
    ja: "国籍"
  },
  birthDateLabel: {
    en: "Birth Date",ch: "出生日期",
    ja: "生年月日"
  },
  change: {
    en: 'Change',
    ja: '変動'
  }
}
