export default {
	incorrectAddressWarning: {
		en: "Please enter a correct address",
		ja: "正しい住所を入力してください"
	},
	walletPlaceholder: {
		en: "Wallet address",
		ja: "ウォレットアドレス"
	},
	title: {
		en: "Verify Wallets",
		ja: "ウォレットの確認"
	},
	verified: {
		en: "Verified",
		ja: "検証済み"
	},
	unverified: {
		en: "Unverified",
		ja: "未確認"
	},
	currentlyConnected: {
		en: "Currently connected",
		ja: "現在接続中"
	},
	verifiedWallets: {
		en: "Your verified wallets",
		ja: "検証済みのウォレット"
	},
	addWalletButton: {
		en: "Add",
		ja: "追加"
	},
	copied:{
		en: 'Copied',
		ja: 'コピー機'
	},
	verifyNewWallet: {
		en: 'Verify new wallet',
		ja: '新しいウォレットを確認する'
	},
	connectWalletToContinue: {
		en: 'Connect wallet to continue',
		ja: 'ウォレットに接続して続行します'
	},
	walletIsNotConnected: {
		en: 'Wallet is not connected',
		ja: 'ウォレットが接続されていません'
	},
	doYouHaveSales: {
		en: 'Have you bought anything from us at trust/launch/store?',
		ja: 'トラスト、ローンチ、またはストアで当社から何かを購入したことがありますか?'
	},
	yes: {
		en: 'Yes',
		ja: 'はい'
	},
	no: {
		en: 'No',
		ja: 'いいえ'
	}
}