export default {
	deposit: {
		en: "Deposit Submission",
		ja: "預金提出",
		ch: "配置初始交易"
	},
	howItWork: {
		en: "How it works?",
		ja: "",
		ch: ""
	},
	fromManager: {
		en: "Message from manager:",
		ja: "マネージャーからのメッセージ:",
		ch: "經理寄語："
	},
	first: {
		en: "1. Choose network and currency.",
		ja: "1.ネットワークと通貨を選択します。",
		ch: "1. 選擇網路和貨幣。"
	},
	second: {
		en: "2. Get wallet and transfer money.",
		ja: "2.財布を手に入れて送金します。",
		ch: "2.獲取錢包並轉帳。"
	},
	third: {
		en: "Complete this step by clicking the button below.",
		ja: "3. 下のボタンをクリックして、この手順を完了します。",
		ch: "3. 按下下面的按鈕完成此步驟。"
	},
	crypto: {
		en: "Cryptocurrency",
		ja: "クリプト",
		ch: "加密貨幣"
	},
	card: {
		en: "Card",
		ja: "カード",
		ch: "卡片"
	},
	cardSoon: {
		en: "Pay by card coming soon",
		ja: "近日カード決済",
		ch: "即將推出刷卡支付"
	},
	dealSize: {
		en: "Deal size",
		ja: "取引サイズ",
	},
	validNetwork: {
		en: "Please select valid network",
		ja: "有効なネットワークを選択してください",
		ch: "請選擇有效網絡"
	},
	network: {
		en: "Select the network",
		ja: "ネットワークを選択します",
		ch: "網絡"
	},
	payment: {
		en: "Choose a payment method",
		ja: "お支払い方法を選択する",
		ch: "網絡"
	},
	currency: {
		en: "Choose the currency",
		ja: "通貨を選択する",
		ch: "貨幣"
	},
	validCurrency: {
		en: "Please select valid currency",
		ja: "有効な通貨を選択してください",
		ch: "請選擇有效貨幣"
	},
	walletForPayment: {
		en: "Transfer the amount to the provided wallet address",
		ja: "指定されたウォレットアドレスに金額を転送します",
		ch: "支付錢包："
	},
	transferredMoney: {
		en: "Confirm Payment",
		ja: "お支払いの確認",
		ch: "我轉了錢"
	},
	receiverWallet: {
		en: "Get receiver wallet",
		ja: "受信者ウォレットを取得する",
		ch: "獲取接收者錢包"
	},
	managerCheck: {
		en: "The manager will check your payment soon.",
		ja: "マネージャーがすぐに支払いを確認します。",
		ch: "經理將很快檢查您的付款。"
	}
}
