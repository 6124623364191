export default {
  pageTitle: {
    en: "Sign in to your account",ch: "登錄您的帳戶",
    ja: "アカウントにサインインする"
  },

  buttonText: {
    en: "Sign in",ch: "登錄",
    ja: "ログイン"
  },
  cancel: {
    en: "Cancel",ch: "取消",
    ja: "キャンセル"
  },
  forgotPassword: {
    en: "Forgot password?",ch: "忘記密碼",
    ja: "パスワードをお忘れですか？"
  },
  signUp: {
    en: "Sign up",
    ja: "サインアップ"
  },

  Code: {
    en: "One time code",
    ja: "ワンタイムコード"
  },

  somethingWentWrong: {
    en: "Something went wrong",
    ja: "エラーが発生しました"
  },
  errorSignIn: {
    en: "Error signing in",
    ja: "サインイン エラー"
  },
  incorrectCredentials: {
    en: "Incorrect credentials",
    ja: "資格情報が正しくありません"
  },

  codeText: {
    en: "A message with a verification code has been sent to your email. Enter the code to continue.",ch: "帶有驗證碼的消息已發送到您的電子郵件。輸入代碼以繼續。",
    ja: "確認コードが記載されたメッセージがメールに送信されました。 コードを入力して続行します。"
  },

  emailAddressLabel: {
    en: "Email",ch: "電子郵件",
    ja: "電子メールアドレス"
  },
  passwordLabel: {
    en: "Password",ch: "密碼",
    ja: "パスワード"
  },

  incorrectEmailWarning: {
    en: "Please enter a correct email",ch: "請輸入正確的電子郵件",
    ja: "正しいメールアドレスを入力してください"
  },
  incorrectPasswordWarning: {
    en: "Password should be longer than 8 characters",ch: "密碼應超過8個字元",
    ja: "パスワードは 8 文字以上にする必要があります"
  }
}
