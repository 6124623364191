import React from 'react';

// Import colors from constants or use currentColor
// If icon uses more than one adjustable color you can pass colors from props
// BUT you must always provide default values for color props

export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_5538)">
        <path
          d="M12.6667 4.66666H16V5.99999H12.6667V4.66666ZM11.3334 7.99999H16V9.33332H11.3334V7.99999ZM13.3334 11.3333H16V12.6667H13.3334V11.3333ZM1.33337 14.6667C1.33337 13.2522 1.89528 11.8956 2.89547 10.8954C3.89567 9.89523 5.25222 9.33332 6.66671 9.33332C8.0812 9.33332 9.43775 9.89523 10.4379 10.8954C11.4381 11.8956 12 13.2522 12 14.6667H10.6667C10.6667 13.6058 10.2453 12.5884 9.49513 11.8382C8.74499 11.0881 7.72757 10.6667 6.66671 10.6667C5.60584 10.6667 4.58843 11.0881 3.83828 11.8382C3.08813 12.5884 2.66671 13.6058 2.66671 14.6667H1.33337ZM6.66671 8.66666C4.45671 8.66666 2.66671 6.87666 2.66671 4.66666C2.66671 2.45666 4.45671 0.666656 6.66671 0.666656C8.87671 0.666656 10.6667 2.45666 10.6667 4.66666C10.6667 6.87666 8.87671 8.66666 6.66671 8.66666ZM6.66671 7.33332C8.14004 7.33332 9.33337 6.13999 9.33337 4.66666C9.33337 3.19332 8.14004 1.99999 6.66671 1.99999C5.19337 1.99999 4.00004 3.19332 4.00004 4.66666C4.00004 6.13999 5.19337 7.33332 6.66671 7.33332Z"
          fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_1_5538">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
