import React from 'react';

export default () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <path
          d="M7.49996 6.61626L10.5937 3.52251L11.4775 4.40626L8.38371 7.50001L11.4775 10.5938L10.5937 11.4775L7.49996 8.38376L4.40621 11.4775L3.52246 10.5938L6.61621 7.50001L3.52246 4.40626L4.40621 3.52251L7.49996 6.61626Z"
          fill="#181833"/>
      </g>
    </svg>
  );
};
