import React from 'react'
import {IconType, RotateType} from 'types/icon'
import {useTheme} from 'styled-components'

const SingleArrow = (props: IconType & RotateType) => {
	const theme = useTheme()
	const {
		width = 7,
		height = 5,
		fill = '#000',
		rotate = 0,
		viewBox = '0 0 7 5',
	} = props

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
		     style={{transform: `rotate(${rotate}deg)`, translate: 'all .3s ease'}}>
			<g opacity="0.5">
				<path
					d="M9.99955 10.9763L14.1244 6.85156L15.3029 8.03007L9.99955 13.3334L4.69629 8.03007L5.8748 6.85156L9.99955 10.9763Z"
					fill="#181833"/>
			</g>
		</svg>
	)
}

export default SingleArrow
