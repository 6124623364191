export default {
  reportYourProblem: {
    en: 'Report your problem',
    ja: 'あなたの問題を報告する',
    ch: '報告您的問題'
  },
  problem: {
    en: 'Problem',
    ja: '問題',
    ch: '问题'
  },
  example: {
    en: 'Example: change of passport',
    ja: '示例：更改護照',
    ch: '例:パスポートの変更'
  },
  send: {
    en: 'Send',
    ja: '送信',
    ch: '發送'
  },
  success: {
    en: "Success",
    ch: "成功",
    ja: "成功",
  },
  youSuccessfullySentReportMessage: {
    en: "You successfully sent report message",
    ch: "你成功發送報告メッセージ",
    ja: "あなたの報告メッセージを送信しました",
  }
}
