import React from 'react';

const UserStatusContext = React.createContext<{
	isUserVerified: boolean,
	isUserSubmitted: boolean,
	isWalletVerified: undefined | boolean,
	isInvestmentAllowed: undefined | boolean,
	setIsWalletVerified: (newValue: boolean) => void
}>({
	isUserVerified: false,
	isUserSubmitted: false,
	isWalletVerified: undefined,
	isInvestmentAllowed: undefined,
	setIsWalletVerified: (newValue) => {}
})

export default UserStatusContext;
