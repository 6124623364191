import React from 'react';

// Import colors from constants or use currentColor
// If icon uses more than one adjustable color you can pass colors from props
// BUT you must always provide default values for color props

export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9016 16.0808C13.436 17.0105 11.7356 17.5028 9.99996 17.5C5.50663 17.5 1.7683 14.2667 0.984131 9.99999C1.34249 8.05893 2.31882 6.28574 3.76746 4.94499L1.15996 2.33999L2.33913 1.16083L18.8383 17.6608L17.6591 18.8392L14.9008 16.0808H14.9016ZM4.9458 6.12499C3.8133 7.15465 3.02439 8.50731 2.6858 9.99999C2.94604 11.1387 3.46852 12.2009 4.21163 13.1022C4.95475 14.0034 5.89799 14.7187 6.96626 15.1911C8.03453 15.6635 9.19834 15.88 10.365 15.8234C11.5317 15.7668 12.6691 15.4386 13.6866 14.865L11.9966 13.175C11.2772 13.6282 10.4251 13.8234 9.58016 13.7287C8.73519 13.634 7.9475 13.2549 7.34627 12.6537C6.74505 12.0525 6.36599 11.2648 6.27127 10.4198C6.17654 9.57483 6.37178 8.72275 6.82496 8.00333L4.9458 6.12499ZM10.7616 11.94L8.05996 9.23833C7.91169 9.61577 7.87679 10.0283 7.95955 10.4253C8.04232 10.8223 8.23915 11.1865 8.5259 11.4732C8.81265 11.76 9.17685 11.9568 9.57384 12.0396C9.97084 12.1223 10.3834 12.0874 10.7608 11.9392L10.7616 11.94ZM17.3391 13.8267L16.1466 12.635C16.7037 11.8411 17.1003 10.946 17.3141 9.99999C17.0877 9.0081 16.6619 8.07262 16.0626 7.25041C15.4633 6.4282 14.7031 5.73643 13.8282 5.21716C12.9533 4.6979 11.9819 4.36197 10.9731 4.2298C9.96428 4.09763 8.93914 4.17198 7.95996 4.44833L6.64496 3.13333C7.68413 2.72499 8.81663 2.49999 9.99996 2.49999C14.4933 2.49999 18.2316 5.73333 19.0158 9.99999C18.7605 11.3881 18.1865 12.698 17.3391 13.8267ZM9.76913 6.25666C10.2996 6.22388 10.8309 6.30422 11.3279 6.49234C11.8249 6.68047 12.2763 6.97209 12.6521 7.34787C13.0279 7.72365 13.3195 8.17502 13.5076 8.67204C13.6957 9.16907 13.7761 9.7004 13.7433 10.2308L9.7683 6.25666H9.76913Z"
        fill="#D2D5DA"/>
    </svg>
  );
};
