export default {
  tileTitle: {
    en: "Residence",ch: "住宅",
    ja: "住居"
  },

  residenceLabel: {
    en: "Residence",
    ja: "住居"
  },
  cityLabel: {
    en: "City",ch: "城市",
    ja: "街"
  },
  postalCodeLabel: {
    en: "Postal code",ch: "郵遞區號",
    ja: "郵便番号"
  },
  mainAddressLabel: {
    en: "Address",ch: "位址",
    ja: "住所№1"
  },
  additionalAddressLabel: {
    en: "Address №2",
    ja: "住所№2"
  },
  region: {
    en: "Region",ch: "地區",
    ja: "地域"
  },
}
