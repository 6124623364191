import React, {useRef, useState} from 'react'
import {Wrapper, CustomSelect, SelectItem, SelectItems} from './styled'
import Text from '../Text'
import SingleArrow from "../../icons/SingleArrow";
import CheckMark from "../../icons/CheckMark";
import useOnClickOutside from "../../Standard/hooks/useOnClickOutside";

type SelectProps = {
	items: { label: string; value: string }[]
	title: string
	onChange: (item: string) => void
	variant?: 'large' | 'small'
}

const Select = (props: SelectProps) => {
	const {title, items, onChange, variant = 'large'} = props
	const [isActive, setIsActive] = useState(false)
	const [selectedItem, setSelectedItem] = useState({value: '', label: ''})

	const selectItemsRef = useRef<HTMLDivElement>(null)
	const handleChange = (item: { value: string; label: string }) => {
		onChange(item.value)
		setIsActive(false)
		setSelectedItem(item)
	}

	useOnClickOutside(selectItemsRef, () => setIsActive(false))

	return (
		<Wrapper ref={selectItemsRef}>
			<CustomSelect variant={variant} onClick={() => setIsActive(!isActive)}>
				<Text
					fontSize={13}
					fontWeight={400}
				>
					{selectedItem.label
						? `${selectedItem.label.charAt(0).toUpperCase()}${selectedItem.label.slice(
							1,
							selectedItem.label.length,
						)}`
						: title}
				</Text>
				<SingleArrow rotate={isActive ? 180 : 0}/>
			</CustomSelect>
			<SelectItems variant={variant} isActive={isActive}>
				{items.map(item => (
					<SelectItem
						variant={variant}
						onClick={() => handleChange(item)}
						key={item.label}
					>
						<Text
							fontSize={13}
							fontWeight={400}
						>
							{item.label.charAt(0).toUpperCase()}
							{item.label.slice(1, item.label.length)}
						</Text>
						{selectedItem.value === item.value && <CheckMark/>}
					</SelectItem>
				))}
			</SelectItems>
		</Wrapper>
	)
}

export default Select
