import React, {useContext} from 'react';
import StepItem from "Standard/components/Stepper/StepItem";
import {JustifyStartColumn,} from "Standard/styles/GlobalStyledComponents";
import Text from "Standard/components/Text";
import {IDealActions, IDealStepStatus, StepStatusEnum, ActionStatusEnum} from "types/ManageStatus";
import TrustButton from "Standard/components/TrustButton";
import styled from "styled-components";
import {localized} from "../../../Standard/utils/localized";
import texts from './localization'
import LocaleContext from "../../../Standard/LocaleContext";

type SignDocumentsProps = {
  status: IDealStepStatus,
  action: IDealActions,
  nextStep: (body: { desiredInvestmentAmount?: number }) => void,
  adminErrorMessage?: string
}

const ButtonWrapper = styled.div`
  width: 180px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const SignDocuments = (props: SignDocumentsProps) => {
  const {status, action, adminErrorMessage, nextStep} = props
  const {locale} = useContext(LocaleContext)

  return (
    <StepItem status={status}>
      <Wrapper>
        <Text fontWeight={500} fontSize={20}>{localized(texts.sign, locale)}</Text>
        <div className={'mb-2'}/>
        {status === StepStatusEnum.ACTIVE &&
          <>
            {(action === ActionStatusEnum.USER_ACTION || action === ActionStatusEnum.USER_ACTION_UNSUCCESSFUL) &&
              <>
                <JustifyStartColumn gap={10}>
                  {adminErrorMessage && <Text fontWeight={400} fontSize={14} color={'#e73d3d'}><strong>{localized(texts.fromManager, locale)}</strong> {adminErrorMessage}</Text>}
                  <ButtonWrapper>
                    <TrustButton style='green' isValid onClick={() => nextStep({})}>{localized(texts.getDocuments, locale)}</TrustButton>
                  </ButtonWrapper>
                </JustifyStartColumn>
              </>
            }
            {action === ActionStatusEnum.ADMIN_ACTION &&
              <Text fontWeight={400} fontSize={14}>{localized(texts.sentDocuments, locale)}</Text>
            }
          </>
        }
      </Wrapper>
      <div className={'mb-4'}/>
    </StepItem>
  );
};

export default SignDocuments;