import React, {useContext, useEffect, useState} from "react";
import './index.css'
import VerificationTile from "../../VerificationTile";
import Text from "../../Text";
import useValidatedState, {validationFuncs} from "../../../Standard/hooks/useValidatedState";
import SimpleInput from "../../../Standard/components/SimpleInput";
import SimpleLabelContainer from "../../../Standard/components/SimpleLabelContainer";
import {InputsStatusEnum} from "../../../types/Input";
import {FieldStatus} from "../../../types/UserData";
import CheckMark from "../../../icons/CheckMark";
import texts from './localization'
import LocaleContext from "../../../Standard/LocaleContext";
import {localized} from "Standard/utils/localized";

type PassportInformationPropType = {
  onChangeData: (data: any) => void,
  fieldsStatus: {
    documentNumber: FieldStatus | undefined
  }
}

const PassportInformationDefaultProps = {}

const PassportInformation = (props: PassportInformationPropType) => {
  const {onChangeData, fieldsStatus} = props

  const {locale} = useContext(LocaleContext)

  const [isFirstRender, setIsFirstRender] = useState(true)
  const [localStorageData, setLocalStorageData] = useState({
    seriesAndNumber: "",
  })

  const [[seriesAndNumber, setSeriesAndNumber], seriesAndNumberValid] = useValidatedState<string>("", validationFuncs.hasValue);

  useEffect(() => {
    if (!isFirstRender) {
      localStorage.setItem('passport', JSON.stringify(localStorageData))
    }
  }, [isFirstRender])

  function setPassportInner(passport: { data: {}, isValid: boolean }) {
    if (!isFirstRender) {
      localStorage.setItem('passport', JSON.stringify(passport.data))
      onChangeData(passport)
    } else {
      setIsFirstRender(false)
    }
  }

  useEffect(() => {
    const passport = localStorage.getItem("passport");
    const parsed = JSON.parse(`${passport}`)
    if(parsed){
      setLocalStorageData(parsed);
    }
    setSeriesAndNumber(localStorageData.seriesAndNumber)
  }, [isFirstRender, localStorageData.seriesAndNumber])

  useEffect(() => {
    setPassportInner(
      {
        data: {seriesAndNumber},
        isValid: seriesAndNumberValid
      }
    );
  }, [seriesAndNumber, seriesAndNumberValid]);

  return (
    <VerificationTile>
      <div>
        <Text fontSize={24} color={"#000"}>{localized(texts.passport, locale)}</Text>
        <div className={"mb-4"}/>
        <SimpleLabelContainer
          displayAsLabel={
            fieldsStatus.documentNumber?.status === InputsStatusEnum.VERIFIED ||
            fieldsStatus.documentNumber?.status === InputsStatusEnum.PROCESSING_BY_ADMIN
          }
          label={localized(texts.seriesAndNumber, locale)} id="seriesAndNumber">
          <SimpleInput
            onChangeRaw={setSeriesAndNumber}
            required
            displayAsLabel={
              fieldsStatus.documentNumber?.status === InputsStatusEnum.VERIFIED ||
              fieldsStatus.documentNumber?.status === InputsStatusEnum.PROCESSING_BY_ADMIN
            }
            inputProps={{
              placeholder: `${localized(texts.seriesAndNumber, locale)}`,
              value: seriesAndNumber,
              type: "number"
            }}
            autoComplete={"country-name"}
            id="seriesAndNumber"
          />
        </SimpleLabelContainer>
        {fieldsStatus.documentNumber && fieldsStatus.documentNumber.status === InputsStatusEnum.VERIFIED && <CheckMark color={'#33CC66'} height={20} width={20}/>}
      </div>
    </VerificationTile>
  )
};

PassportInformation.defaultProps = PassportInformationDefaultProps

export default PassportInformation