import React, {useContext, useState} from 'react';
import {RowCentered, JustifyCenterColumn} from "Standard/styles/GlobalStyledComponents";
import styled from "styled-components";
import './index.css';
import Text from 'components/Text'
import TrustButton from "../../Standard/components/TrustButton";
import useValidatedState, {validationFuncs} from "../../Standard/hooks/useValidatedState";
import SimpleLabelContainer from "../../Standard/components/SimpleLabelContainer";
import SimpleInput from "../../Standard/components/SimpleInput";
import {API_URL} from "../../api/constants";
import {useCookies} from "react-cookie";
import Spinner from "../../Standard/components/Spinner";
import NotificationContext from "../../Standard/utils/NotificationContext";
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import texts from "./localization"

interface KycModalProps {
  onSubmit: () => void
}


const ChooseTierBlock = styled(RowCentered)`
  border-radius: 30px;
  background: #fff;
  width: 440px;
  height: max-content;
  padding: 25px;
  gap: 15px;
  
  @media screen and (max-width: 1200px) {
    width: 300px
  }
`

const ButtonWrapper = styled.div`
  width: 220px;
`

const KycModal = (props: KycModalProps) => {
  const {locale} = useContext(LocaleContext);
  const {onSubmit} = props
  const [[problem, setProblem], problemValid] = useValidatedState<string>("", validationFuncs.hasValue);

  const [isLoading, setIsLoading] = useState(false)

  const [cookies] = useCookies(["auth"]);

  const notification = useContext(NotificationContext)

  const sendProblemMessage = () => {

    if (problem === "") {
      return
    }

    setIsLoading(true)
    const sendProblemMessageUrl = `${API_URL}/user-messages/send`

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": cookies.auth
      },
      body: JSON.stringify({
        message: problem
      })
    };

    fetch(sendProblemMessageUrl, requestOptions)
      .then(() => {
        notification.displayNotification(
          localized(texts['success'], locale),
          localized(texts['youSuccessfullySentReportMessage']),
        )
      })
      .finally(() => {
        setIsLoading(false)
        setProblem('')
        onSubmit()
      })
  }

  return (
    <JustifyCenterColumn>
      <ChooseTierBlock>
        <Text fontWeight={700} fontSize={24} color={'#33CC66'}>{localized(texts['reportYourProblem'], locale)}</Text>
        <SimpleLabelContainer label={localized(texts['problem'], locale)}>
          <SimpleInput
            onlyEmmitOnBlur
            onChangeRaw={setProblem}
            required
            isValid={problemValid}
            inputProps={{
              className: `w-full`,
              placeholder: localized(texts['example'], locale),
              value: problem
            }}
          />
        </SimpleLabelContainer>
        <ButtonWrapper>
          <TrustButton style={'green'} isValid={problem !== ""} onClick={sendProblemMessage}>
            {
              isLoading ?
              <Spinner color={'#fff'} size={isLoading ? 25 : 0}/>
              :
              <span>{localized(texts['send'], locale)}</span>
            }
          </TrustButton>
        </ButtonWrapper>
      </ChooseTierBlock>
    </JustifyCenterColumn>
  );
};

export default KycModal;