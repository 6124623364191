import React, {useContext, useEffect} from 'react';
import {JustifyStartColumn} from 'Standard/styles/GlobalStyledComponents';
import SimpleLabelContainer from "Standard/components/SimpleLabelContainer";
import TrustButton from "Standard/components/TrustButton";
import useValidatedState, {validationFuncs, validationFuncsFactory} from "Standard/hooks/useValidatedState";
import SimpleAutocomplete from "Standard/components/SimpleAutocomplete";
import SimpleInput from "Standard/components/SimpleInput";
import styled from "styled-components";


import Text from "Standard/components/Text";
import {useUserDataUpdate} from "hooks/useUpdateUserData";
import {useUserAccountInfo} from "hooks/useUserAccountInfo";
import {API_URL} from "api/constants";
import Spinner from "../../Standard/components/Spinner";
import ErrorMessage from "../../components/ErrorMessage";
import {AnimatedWrapper} from "../../styles/StyledComponents";
import {useNavigate} from "react-router-dom";
import {RouteName} from "../../router";
import NotificationContext from "../../Standard/utils/NotificationContext";
import ReturnPanel from "../../components/ReturnPanel";
import NoPageError from "../../Standard/components/404";
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import texts from "./localization"


const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 36px 15%;
  width: 100%;
  z-index: 1;


  @media (max-width: 768px) {
    padding: 36px 7%;
  }
`

const Wrapper = styled(JustifyStartColumn)`
  /* min-width: 480px; */
  width: 100%;
  max-width: 700px;
  margin-top: 25px;
`

const TitleText = styled.p`
  margin: 0px;
  font-weight: 500;
  font-size: 36px;
  line-height: 127%;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`

const ButtonWrapper = styled.div`
  width: 180px;
  margin-bottom: 20px;
`

const ChangeCommunicationMethodModal = () => {

  const {locale} = useContext(LocaleContext);

  const {
    fetchUserAccountInfo,
    email,
    isServerError: isUserAccountInfoServerError
  } = useUserAccountInfo(`${API_URL}/api/users/contacts`)

  const notification = useContext(NotificationContext)
  const history = useNavigate()

  const methods = [
    {type: 'TELEGRAM', value: 'telegram'},
    {type: 'EMAIL', value: 'email'},
    {type: 'WHATSAPP', value: 'whatsapp'}
  ]

  const [[communicationMethods, setCommunicationMethods], communicationMethodsValid] = useValidatedState<string>("", validationFuncsFactory.inArray<string>(methods.map(method => method.value)));
  const [[personalContact, setPersonalContact], personalDataValid] = useValidatedState<string>("", validationFuncs.hasValue);

  const isValid = communicationMethodsValid && personalDataValid

  const {fetchData, isServerError, isLoading, serverErrorMessage, isWaitingForCode} = useUserDataUpdate(
    {
      login: email,
      type: communicationMethods,
      contact: personalContact
    },
    `${API_URL}/api/contacts/update`
  )

  const updateCommunicationMethod = () => {
    if (!isValid) return
    fetchData()
  }

  useEffect(() => {
    fetchUserAccountInfo()
  }, [])

  useEffect(() => {
    if (isWaitingForCode) {
      history(RouteName.ACCOUNT)
      notification.displayNotification(
        localized(texts['success'], locale),
        localized(texts['youSuccessfullyUpdateYourPersonalData'], locale),
      )
    }
  }, [isWaitingForCode])

  return (
    <>
      {isUserAccountInfoServerError && <NoPageError isServerError={isUserAccountInfoServerError}/>}
      {!isUserAccountInfoServerError &&
        <Container>
          <ReturnPanel appUrl={RouteName.ACCOUNT} title={'ACCOUNT'}/>
          <Wrapper>
            <TitleText>Change communication method</TitleText>
            <div className={'mt-5'}/>
            <SimpleLabelContainer
              label={localized(texts["communicationMethod"], locale)}
              id="shipping country-name"
            >
              <SimpleAutocomplete
                isValid={communicationMethodsValid}
                onChangeRaw={setCommunicationMethods}
                errorTooltipText={localized(texts["invalidCommunicationMethod"], locale)}
                required
                placeholder={localized(texts["communicationMethod"], locale)}
                autoComplete={"communication-method"}
                name={"communication-method"}
                id={"communication-method"}
                options={methods.map(method => {
                  return ({value: method.value})
                })}
                value={communicationMethods}
              />
            </SimpleLabelContainer>
            {communicationMethods && communicationMethodsValid &&
              <SimpleLabelContainer label={`${localized(texts["writeYour"], locale)} ${communicationMethods} ${localized(texts["contacts"], locale)}`} id="new-password-text-field">
                <SimpleInput
                  hasIcon
                  required
                  isValid={personalDataValid}
                  inputProps={{
                    placeholder: localized(texts["contacts"], locale),
                    type: 'text',
                    name: "new-password",
                    className: "w-full"
                  }}
                  autoComplete={"new-password"}
                  id="new-password-text-field"
                  onChangeRaw={setPersonalContact}
                />
              </SimpleLabelContainer>
            }
            <ButtonWrapper>
              <TrustButton
                style='green'
                isValid={isValid}
                onClick={updateCommunicationMethod}>
                {
                  isLoading ?
                    <Spinner color="white" size={25}/>
                    :
                    <span>{localized(texts["changeMethod"], locale)}</span>
                }
              </TrustButton>
            </ButtonWrapper>
            <AnimatedWrapper isActive={isServerError}>
              {isServerError &&
                <ErrorMessage message={serverErrorMessage} title={localized(texts["communicationMethodChangeError"], locale)}/>}
            </AnimatedWrapper>
          </Wrapper>
        </Container>
      }
    </>
  );
};

export default ChangeCommunicationMethodModal;