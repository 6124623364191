interface EthereumProvider {
	isMetaMask?: boolean;
}

declare global {
	interface Window {
		ethereum?: EthereumProvider;
	}
}
export function isMetaMaskInstalled(): boolean {
	return window.ethereum?.isMetaMask || false;
}