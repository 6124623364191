import React, {useContext, useEffect, useState} from "react";
import texts from './localization'
import LocaleContext from "Standard/LocaleContext";
import {localized} from "Standard/utils/localized";
import SubHeader from "components/SubHeader";
import styled from "styled-components";
import ManageBackground from "icons/ManageBackground";
import {Row, JustifyStartColumn, StartRow} from "Standard/styles/GlobalStyledComponents";
import Text from 'Standard/components/Text';
import DealControlling from "components/DealControlling";
import ReturnPanel from "components/ReturnPanel";
import {RouteName} from "router";
import {API_URL} from "api/constants";
import {useCurrentDeal} from "hooks/useCurrentDeal";
import { useParams } from "react-router-dom";
import NoPageError from "../../Standard/components/404";

type ManagePropType = {}

const ManageDefaultProps = {}

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 36px;
  width: 100%;
  z-index: 1;
`

const CurrentDeal = styled(StartRow)`
  background: #fff;
  box-shadow: 0 0 27px rgba(94, 103, 120, 0.1);
  border-radius: 16px;
  padding: 27px 20px;
  width: 300px;
  z-index: 10;
  margin-bottom: 16px;
`

const ManagePanelWrapper = styled(JustifyStartColumn)`
  padding-left: 20%;
  padding-right: 20%;

  @media screen and (max-width: 900px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const DealImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`

const Timer = styled(StartRow)`
  justify-content: flex-start;
  background: #fff;
  box-shadow: 0 0 27px rgba(94, 103, 120, 0.1);
  border-radius: 16px;
  padding: 27px 20px;
  width: 300px;
  z-index: 10;
`

const DealWrapper = styled(Row) `
  @media screen and (max-width: 900px) {
    align-items: flex-start;
    flex-direction: column;
  }
`

const Manage = (props: ManagePropType) => {
  const {locale} = useContext(LocaleContext)
  let { id } = useParams();

  const {fetchCurrentDeal, currentDeal, nextStep} = useCurrentDeal(id ? id : '')

  useEffect(() => {
    fetchCurrentDeal()
  }, [])

  if (!currentDeal) {
    return <NoPageError />
  }

  return (
    <Container>
      <SubHeader
        backgroundIcon={<ManageBackground/>}
        greenTitle={localized(texts.manage, locale)}
      />
      <ManagePanelWrapper>
        <ReturnPanel appUrl={RouteName.ALL_DEALS} title={localized(texts.deals, locale)} />
        <div className={'mb-5'} />
        <DealWrapper gap={16}>
          <JustifyStartColumn>
            <CurrentDeal gap={9}>
              <DealImage src={`${API_URL}/${currentDeal?.investment?.logoPath}`.replaceAll(' ', '%20')} />
              <JustifyStartColumn>
                <Text fontWeight={600} fontSize={15}>{currentDeal?.investment?.aboutSubtitle}</Text>
                <Text fontWeight={400} fontSize={14}>Last round: {currentDeal?.investment?.headerTextSecond}</Text>
              </JustifyStartColumn>
            </CurrentDeal>
          </JustifyStartColumn>
          <DealControlling currentDeal={currentDeal} nextStep={nextStep}/>
        </DealWrapper>
      </ManagePanelWrapper>
    </Container>
  )
};

Manage.defaultProps = ManageDefaultProps

export default Manage

