export default {
  error2FA: {
    en: "2FA Error",
  },
  messageSend: {
    en: "A message with a verification code has been sent to your email. Enter the code to continue.",ch: "帶有驗證碼的消息已發送到您的電子郵件。輸入代碼以繼續。",
    ja: "確認コードが記載されたメッセージがメールに送信されました。 コードを入力して続行します。"
  },
  cancel: {
    en: "Cancel",ch: "取消",
    ja: "キャンセル"
  },
  twoFactorAuthentication: {
    en: "Two-Factor Authentication - 2FA",ch: "雙因素身份驗證 - 2FA",ja: "二要素認証 - 2FA"
  }
}
