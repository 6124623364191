import React, {useContext, useEffect, useState} from 'react';
import StepItem from "Standard/components/Stepper/StepItem";
import {AlignCenterRow} from "Standard/styles/GlobalStyledComponents";
import Text from "Standard/components/Text";
import TrustButton from "Standard/components/TrustButton";
import styled from "styled-components";
import {IDealActions, IDealStepStatus, StepStatusEnum, ActionStatusEnum} from "types/ManageStatus";
import SimpleLabelContainer from "../../../Standard/components/SimpleLabelContainer";
import useValidatedState, {validationFuncsFactory} from "../../../Standard/hooks/useValidatedState";
import Web3 from "web3";
import {API_URL} from "../../../api/constants";
import {useCookies} from "react-cookie";
import {useUserAccountInfo} from "../../../hooks/useUserAccountInfo";
import {useParams} from "react-router-dom";
import {localized} from "../../../Standard/utils/localized";
import texts from './localization'
import LocaleContext from "../../../Standard/LocaleContext";
import Select from '../../Select'
import Copy from '../../../icons/Copy'
import * as net from "net";

const TronWeb = require("tronweb");

type WaitDealConfirmationProps = {
	status: IDealStepStatus,
	action: IDealActions,
	nextStep: (body: { desiredInvestmentAmount?: number }) => void,
	adminErrorMessage?: string,
	isPrePaid: boolean | undefined
	price: number | undefined
}

const networks: { [key: string]: { name: string, currencies: { name: string, pair: string }[] } } = {
	'ERC-20': {
		name: 'ETH',
		currencies: [
			{name: 'USDT', pair: 'ETH_USDT'},
			{name: 'USDC', pair: 'ETH_USDC'},
		]
	},
	'BEP-20': {
		name: 'BSC',
		currencies: [
			{name: 'USDT', pair: 'BSC_USDT'},
			{name: 'USDC', pair: 'BSC_USDC'},
		]
	}
}

const ButtonWrapper = styled.div`
  width: 180px;
`

const AutoCompleteWrapper = styled.div`
  min-width: 155px;
`

const Wrapper = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.gap}px;
`

const TransferWalletWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  border: 1px solid rgba(24, 24, 51, 0.10);
  background: #FFF;
  gap: 30px;
  width: 420px;
  height: 40px;
  padding: 14px;
  font-size: 14px;
  font-weight: 600;
`
const CopyIcon = styled.div`
  cursor: pointer;
`
const DealSizeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
`

const DepositFunds = (props: WaitDealConfirmationProps) => {
	const {status, action, nextStep, adminErrorMessage, isPrePaid, price} = props
	const {locale} = useContext(LocaleContext)

	const [[network, setNetwork], networkValid] = useValidatedState<string>('', validationFuncsFactory.inArray<string>(Object.keys(networks).map(network => network)))
	const [[cryptoCurrency, setCryptoCurrency], cryptoCurrencyValid] = useValidatedState<string>('', validationFuncsFactory.inArray<string>(networks[network]?.currencies.map(network => network.name)))
	const [account, setAccount] = useState<{ wallet: string, privateKey: string }>({wallet: '', privateKey: ''})
	const [activeButton, setActiveButton] = useState<string>('Crypto')

	const [cookies] = useCookies(["auth"]);
	const {id} = useParams()

	const buttons = [
		{
			label: `${localized(texts.crypto, locale)}`,
			value: `${localized(texts.crypto, locale)}`
		},
		{
			label: `${localized(texts.card, locale)}`,
			value: `${localized(texts.card, locale)}`
		}
	]

	const {
		fetchUserAccountInfo,
		email,
	} = useUserAccountInfo(`${API_URL}/api/users/contacts`)
	const generateWallet = async () => {
		if (network === 'TRC-20') {
			const generatedAccount = await TronWeb.createAccount()
			setAccount({...account, wallet: generatedAccount.address.base58, privateKey: generatedAccount.privateKey})
		} else {
			const web3 = new Web3(new Web3.providers.HttpProvider(`${network === 'ERC-20' ? 'https://rpc.ankr.com/eth' : 'https://bsc-dataseed1.binance.org/'}`))
			const generatedAccount = await web3.eth.accounts.create()
			setAccount({...account, wallet: generatedAccount.address, privateKey: generatedAccount.privateKey})
		}
	}
	const sendPayment = async () => {
		const pair = (networks[network].currencies.find(currency => currency.name === cryptoCurrency))?.pair
		const sendPaymentUrl = `${API_URL}/api/payments`

		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": cookies.auth
			},
			body: JSON.stringify({
				login: email,
				wallet: '0x82FFe2b9e3724656a53f87a620830C36E992889c',
				network: networks[network].name,
				token: pair,
				dealId: id
			})
		};

		fetch(sendPaymentUrl, requestOptions).then(console.log)
	}

	const copyToClipboard = async () => {
		await navigator.clipboard.writeText('0x82FFe2b9e3724656a53f87a620830C36E992889c')
	}

	useEffect(() => {
		setAccount({wallet: '', privateKey: ''})
	}, [network])

	useEffect(() => {
		generateWallet()
		fetchUserAccountInfo()
	}, [])

	useEffect(() => {
		if (isPrePaid && status === 'ACTIVE') {
			nextStep({})
		}
	}, [isPrePaid, nextStep, status])

	const activeStableCoins = networks[network]

	return (
		<StepItem status={status}>
			<Wrapper>
				<Text fontWeight={600} fontSize={24}>{localized(texts.deposit, locale)}</Text>
				<div className={'mb-2'}/>
				{status === StepStatusEnum.ACTIVE &&
          <>
						{(action === ActionStatusEnum.USER_ACTION || action === ActionStatusEnum.USER_ACTION_UNSUCCESSFUL) &&
              <>
                <Wrapper>
									{
										adminErrorMessage &&
                    <Text fontWeight={400} fontSize={14}
                          color={'#e73d3d'}><strong>{localized(texts.fromManager, locale)}</strong> {adminErrorMessage}
                    </Text>
									}
                  <DealSizeWrapper>
                    <Text fontWeight={500} fontSize={16}>{localized(texts.dealSize, locale)}</Text>
                    <Text fontWeight={600} fontSize={24}>${price}</Text>
                  </DealSizeWrapper>
                  <div className={'mb-4'}/>
                  <AutoCompleteWrapper>
                    <SimpleLabelContainer
                      label={localized(texts.payment, locale)}
                      id="payment"
                    >
                      <Select
                        items={buttons}
                        title={'Payment method'}
                        onChange={setActiveButton}
                      />
                    </SimpleLabelContainer>
                  </AutoCompleteWrapper>
                  <div className={'mb-4'}/>
									{activeButton === 'Card' &&
                    <Text fontWeight={400} fontSize={14}>{localized(texts.cardSoon, locale)}</Text>
									}
									{activeButton === 'Cryptocurrency' &&
                    <>
                      <AlignCenterRow gap={15}>
                        <AutoCompleteWrapper>
                          <SimpleLabelContainer
                            label={localized(texts.network, locale)}
                            id="network"
                          >
                            <Select
                              items={Object.keys(networks).map(network => {
																return ({value: network, label: network})
															})}
                              title={'Network'}
                              onChange={setNetwork}
                            />
                          </SimpleLabelContainer>
                        </AutoCompleteWrapper>
												{networkValid && (
													<AutoCompleteWrapper>
														<SimpleLabelContainer
															label={localized(texts.currency, locale)}
															id="currency"
														>
															<Select
																items={activeStableCoins?.currencies.map(currency => {
																	return ({value: currency.name, label: currency.name})
																})}
																title={'Currency'}
																onChange={setCryptoCurrency}
															/>
														</SimpleLabelContainer>
													</AutoCompleteWrapper>
												)}
                      </AlignCenterRow>
                      <div className={'mb-4'}/>
											{cryptoCurrencyValid && (
												<Wrapper gap={10}>
													<Text fontSize={16} fontWeight={500}>{localized(texts.walletForPayment, locale)}</Text>
													<TransferWalletWrapper>
														0x82FFe2b9e3724656a53f87a620830C36E992889c
														<CopyIcon onClick={copyToClipboard}>
															<Copy/>
														</CopyIcon>
													</TransferWalletWrapper>
													<ButtonWrapper>
														<TrustButton style='green' isValid onClick={() => {
															sendPayment()
															nextStep({})
														}}
														>
															{localized(texts.transferredMoney, locale)}
														</TrustButton>
													</ButtonWrapper>
												</Wrapper>
											)}
                    </>
									}
                </Wrapper>
              </>
						}
						{action === ActionStatusEnum.ADMIN_ACTION &&
              <Text fontWeight={400} fontSize={14}>{localized(texts.managerCheck, locale)}</Text>
						}
          </>
				}
			</Wrapper>
			<div className={'mb-4'}/>
		</StepItem>
	);
};

export default DepositFunds;
