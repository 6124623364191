export default {
  manage: {
    en: "Manage",
    ch: "管理",
    ja: "管理"
  },
  deals: {
    en: "DEALS",
    ja: "取引",
    ch: "交易"
  }
}
