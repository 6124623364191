import React from 'react';

const Copy = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.6">
				<path
					d="M5.83317 4.99984V2.49984C5.83317 2.0396 6.20627 1.6665 6.6665 1.6665H16.6665C17.1267 1.6665 17.4998 2.0396 17.4998 2.49984V14.1665C17.4998 14.6268 17.1267 14.9998 16.6665 14.9998H14.1665V17.4991C14.1665 17.9598 13.7916 18.3332 13.3275 18.3332H3.33888C2.87549 18.3332 2.5 17.9627 2.5 17.4991L2.50217 5.8339C2.50225 5.37326 2.8772 4.99984 3.34118 4.99984H5.83317ZM4.16868 6.6665L4.16682 16.6665H12.4998V6.6665H4.16868ZM7.49983 4.99984H14.1665V13.3332H15.8332V3.33317H7.49983V4.99984Z"
					fill="#181833"/>
			</g>
		</svg>
	);
};

export default Copy;
