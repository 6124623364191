import React, {useContext, useEffect, useRef, useState} from 'react';
import SimpleLabelContainer from "Standard/components/SimpleLabelContainer";
import useValidatedState, {validationFuncs} from "Standard/hooks/useValidatedState";
import SimpleInput from "Standard/components/SimpleInput";
import ShowAndHidePassword from "components/ShowAndHidePassword";
import {API_URL} from 'api/constants';
import sha256 from "crypto-js/sha256";
import {useUserDataUpdate} from "hooks/useUpdateUserData";
import UpdateUserDataWrapper from "components/UpdateUserDataWrapper";
import {RouteName} from "router";
import {usePrevious} from 'Standard/hooks/usePrevious';
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import texts from "./localization"

const ForgotPassword = () => {

  const {locale} = useContext(LocaleContext);

  const [[newPassword, setNewPassword], newPasswordValid] = useValidatedState<string>("", validationFuncs.validPassword);
  const [[newRepeatedPassword, setNewRepeatPassword], newRepeatedPasswordValid] = useValidatedState<string>("", newValue => newValue === newPassword);
  const [[email, setEmail], emailValid] = useValidatedState<string>("", validationFuncs.isEmail);

  const [newPasswordType, setNewPasswordType] = useState<"text" | "password">("password")
  const [newRepeatedPasswordType, setNewRepeatedPasswordType] = useState<"text" | "password">("password")

  const prevNewPasswordRef = usePrevious(newPassword)
  const prevNewRepeatedPasswordRef = usePrevious(newRepeatedPassword)

  const isValid = newPasswordValid && newRepeatedPasswordValid

  const {
    fetchData,
    isServerError,
    serverErrorMessage,
    isWaitingForCode,
    isLoading,
    setIsWaitingForCode,
    setIsServerError
  } = useUserDataUpdate(
    {
      login: email,
      updatedPassword: sha256(newPassword).toString(),
    },
    `${API_URL}/api/auth/recovery/password`
  )

  const setNewUserPassword = () => {
    if (!isValid) return
    fetchData()
  }

  useEffect(() => {
    if (isServerError && (prevNewRepeatedPasswordRef !== newRepeatedPassword || prevNewPasswordRef !== newPassword)) {
      setIsServerError(false)
    }
  }, [isServerError, newRepeatedPassword, newPassword])

  return (
    <UpdateUserDataWrapper
      title={localized(texts['forgotPassword'], locale)}
      submitButtonText={localized(texts["updatePassword"], locale)}
      isValid={isValid}
      submitFunction={setNewUserPassword}
      isLoading={isLoading}
      isServerError={isServerError}
      serverErrorMessage={serverErrorMessage}
      isWaitingForCode={isWaitingForCode}
      setIsWaitingForCode={setIsWaitingForCode}
      email={email}
      sendCodeUrl={`${API_URL}/api/auth/recovery/password/code`}
      appUrl={RouteName.LOGIN}
      returnPanelTitle={'SIGN IN'}
    >
      <SimpleLabelContainer
      label={localized(texts['emailAddressLabel'], locale)}
    >
      <SimpleInput
        displayAsLabel={isWaitingForCode}
        onlyEmmitOnBlur
        onChangeRaw={setEmail}
        required
        isValid={emailValid}
        errorTooltipText={localized(texts['incorrectEmail'], locale)}
        inputProps={{
          className: `w-full`,
          placeholder: localized(texts['emailAddressLabel'], locale),
          value: email
        }}
      />
    </SimpleLabelContainer>
      <SimpleLabelContainer label={localized(texts['newPassword'], locale)} id="new-password-text-field">
        <SimpleInput
          hasIcon
          Icon={<ShowAndHidePassword passwordType={newPasswordType} setPasswordType={setNewPasswordType}/>}
          required
          isValid={newPasswordValid}
          errorTooltipText={localized(texts['passwordShouldBeLongerThan8Characters'], locale)}
          inputProps={{
            placeholder: localized(texts['passwordLabel'], locale),
            type: `${newPasswordType}`,
            name: "new-password",
            className: "w-full"
          }}
          autoComplete={"new-password"}
          id="new-password-text-field"
          onChangeRaw={setNewPassword}
        />
      </SimpleLabelContainer>
      <SimpleLabelContainer label={localized(texts['repeatNewPassword'], locale)} id="confirm-password-text-field">
        <SimpleInput
          hasIcon
          Icon={<ShowAndHidePassword passwordType={newRepeatedPasswordType}
                                     setPasswordType={setNewRepeatedPasswordType}/>}
          required
          isValid={newRepeatedPasswordValid}
          id="confirm-password-text-field"
          errorTooltipText={localized(texts['passwordsNotMatchWarning'], locale)}
          inputProps={{
            placeholder: localized(texts['passwordLabel'], locale),
            type: `${newRepeatedPasswordType}`,
            name: "new-password",
            className: "w-full"
          }}
          autoComplete={"new-password"}
          onChangeRaw={setNewRepeatPassword}
        />
      </SimpleLabelContainer>
    </UpdateUserDataWrapper>
  );
};

export default ForgotPassword;