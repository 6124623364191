export default {
	confirm: {
		en: "KYC Confirmation",
		ja: "KYCの確認",
		ch: "確認 KYC"
	},
	passing: {
		en: "You are already verified in the KYC system.",
		ja: "KYCシステムですでに検証されています。",
		ch: "感謝您通過 KYC。您可以繼續下一步"
	},
	completeKYC: {
		en: "To continue, you must complete KYC",
		ja: "続行するには、KYC を完了する必要があります",
		ch: "要繼續，您必須完成 KYC"
	},
	nextStep: {
		en: "Next step",
		ja: "次のステップ",
		ch: "下一步"
	}
}
