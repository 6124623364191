import React, {useContext, useEffect} from 'react';
import {JustifyStartColumn, AlignCenterRow} from "Standard/styles/GlobalStyledComponents";
import Text from "Standard/components/Text";
import SimpleLabelContainer from "Standard/components/SimpleLabelContainer";
import useValidatedState, {validationFuncs} from "Standard/hooks/useValidatedState";
import StepItem from "Standard/components/Stepper/StepItem";
import SimpleInput from "Standard/components/SimpleInput";
import TrustButton from "Standard/components/TrustButton";
import styled from "styled-components";
import {useUserAccountInfo} from "hooks/useUserAccountInfo";
import {API_URL} from "api/constants";
import {useNavigate} from "react-router-dom";
import {RouteName} from "router";
import {IDealStepStatus, IDealActions, StepStatusEnum, ActionStatusEnum} from "types/ManageStatus";
import {useWeb3React} from "@web3-react/core";
import {localized} from "../../../Standard/utils/localized";
import texts from './localization'
import LocaleContext from "../../../Standard/LocaleContext";
import {lowerFirst} from "lodash";

type PaymentStatusProps = {
	status: IDealStepStatus,
	action: IDealActions,
	nextStep: (body: { desiredInvestmentAmount?: number }) => void,
	adminErrorMessage?: string,
	price: number | undefined
}

const ButtonWrapper = styled.div`
  width: 252px;
  margin-bottom: 20px;
`

const NoContactsLink = styled.div`
  text-decoration: underline;
  cursor: pointer;
  transition: opacity .4s;

  &:hover {
    opacity: 0.3;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

const PaymentStatus = (props: PaymentStatusProps) => {
	const {status, action, nextStep, adminErrorMessage, price} = props
	const {locale} = useContext(LocaleContext)

	const [[amount, setAmount], amountValid] = useValidatedState<string>(price ? `${price}` : "", validationFuncs.hasValue);

	const {fetchUserAccountInfo, communicationMethod} = useUserAccountInfo(`${API_URL}/api/users/contacts`)

	const history = useNavigate()

	useEffect(() => {
		fetchUserAccountInfo()
	}, [])

	const isAmountValid = amountValid && Number(amount) >= 100

	return (
		<StepItem status={status}>
			<Wrapper>
				<Text fontWeight={600} fontSize={24}>{localized(texts.configure, locale)}</Text>
				<div className={'mb-2'}/>
				{status === StepStatusEnum.ACTIVE &&
          <>
						{(action === ActionStatusEnum.USER_ACTION || action === ActionStatusEnum.USER_ACTION_UNSUCCESSFUL) &&
              <>
                <Text fontWeight={500} fontSize={16}>
									{price ?
										`${localized(texts.checkAmount, locale)}`
										:
										`${localized(texts.getStarted, locale)}`
									}
                </Text>
                <div className={'mb-2'}/>
								{adminErrorMessage && <Text fontWeight={400} fontSize={14}
                                            color={'#e73d3d'}><strong>${localized(texts.fromManager, locale)}</strong> {adminErrorMessage}
                </Text>}
                <div className={'mb-2'}/>
                <AlignCenterRow>
                  <SimpleLabelContainer
                    label={localized(texts.amountIn, locale)}
                    displayAsLabel={!!price}
                  >
                    <SimpleInput
                      displayAsLabel={!!price}
                      onlyEmmitOnBlur
                      required
                      isValid={amountValid}
                      onChangeRaw={setAmount}
                      errorTooltipText={`Field is required`}
                      inputProps={{
												className: `w-full`,
												placeholder: `${localized(texts.amountIn, locale)}`,
												value: amount,
												type: 'number',
											}}
                    />
                  </SimpleLabelContainer>
                </AlignCenterRow>
                <ButtonWrapper>
                  <TrustButton
	                  style='green'
	                  isValid={isAmountValid || price !== undefined}
                    isDisabled={!isAmountValid}
	                  onClick={isAmountValid || !!price ? () => nextStep({desiredInvestmentAmount: price ? +price : +amount}) : () => {}}
                  >
	                  {localized(texts.confirm, locale)}
									</TrustButton>
                </ButtonWrapper>
              </>
						}
						{action === ActionStatusEnum.ADMIN_ACTION &&
              <Text fontWeight={400}
                    fontSize={14}>{localized(texts.submitted, locale)}<br/>{localized(texts.waitResponse, locale)}
              </Text>
						}
          </>
				}
			</Wrapper>
			<div className={'mb-4'}/>
		</StepItem>
	);
};

export default PaymentStatus;
