import React, {useEffect, useState} from "react";
import StandardAppContainer from "Standard/StandardAppContainer";
import AppRouter from "router/AppRouter";
import ConnectorButton from "components/ConnectorButtons/ButtonExample";
import LogoutButton from "components/ConnectorButtons/Logout";
import AccountIcon from 'icons/Account';
import PaymentIcon from 'icons/PaymentMethod';
import PersonalIcon from 'icons/PersonalData';
import LogoutIcon from 'icons/Logout';
import {RouteName} from "router";
import {ConnectorButtonsEnum} from "types/ConnectorButtons";
import NavItem from "components/Header/Navigation";
import GradientCircles from "./Standard/decorations/GradientCircles";
import {NavItemsEnum} from "types/NavItems";
import UserStatusContext from 'context/UserStatusContext';
import {API_URL} from "./api/constants";
import {useCookies} from "react-cookie";
import DisconnectButton from "./components/ConnectorButtons/Disconnect";
import {useWeb3React} from "@web3-react/core";

export const App = () => {
	// @ts-ignore
	const {account} = useWeb3React();
	const [isUserVerified, setIsUserVerified] = useState(false)
	const [isUserSubmitted, setIsUserSubmitted] = useState(false)
	const [isWalletVerified, setIsWalletVerified] = useState<boolean | undefined>(undefined)
	const [isInvestmentAllowed, setIsInvestmentAllowed] = useState<boolean | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [buttons, setButtons] = useState([
		<ConnectorButton text={ConnectorButtonsEnum.ACCOUNT} url={RouteName.ACCOUNT} icon={<AccountIcon/>}/>,
		<ConnectorButton text={ConnectorButtonsEnum.PERSONAL_DATA} url={RouteName.VERIFICATION} icon={<PersonalIcon/>}/>,
		<ConnectorButton text={ConnectorButtonsEnum.VERIFY_WALLETS} url={RouteName.VERIFY_WALLETS} icon={<PaymentIcon/>}/>,
		<DisconnectButton/>,
		<LogoutButton text={ConnectorButtonsEnum.LOGOUT} icon={<LogoutIcon/>} url={RouteName.LOGIN}/>,
	]);

	const [navItems, setNavItems] = useState([
		<NavItem title={NavItemsEnum.INVEST} route={RouteName.INVEST}/>,
		<NavItem title={NavItemsEnum.MANAGE} route={RouteName.ALL_DEALS}/>,
	])

	const [cookies] = useCookies(['auth'])

	async function getStatusOfUser() {
		if (cookies.auth) {
			setIsLoading(true)
			const userStatusUrl = `${API_URL}/api/validation/token?wallet=${account}`;

			const requestOptions = {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Authorization": cookies.auth
				}
			};

			fetch(userStatusUrl, requestOptions)
				.then(res => res.json())
				.then(json => {
					setIsInvestmentAllowed(json.isInvestmentAllowed)
					setIsWalletVerified(json.walletIsVerified)
					setIsUserVerified(json.isVerified)
					setIsUserSubmitted(json.isSubmitted)
				})
				.catch(e => console.log(e))
				.finally(() => setIsLoading(false))
		}
	}

	useEffect(() => {
		getStatusOfUser()
	}, [account, cookies.auth])

	useEffect(() => {
		if (window.innerWidth < 900) {
			setNavItems([])
			setButtons(prevButtons => [
				<ConnectorButton text={ConnectorButtonsEnum.MANAGE} url={RouteName.ALL_DEALS} icon={<PaymentIcon/>}/>,
				<ConnectorButton text={ConnectorButtonsEnum.INVEST} url={RouteName.INVEST} icon={<PaymentIcon/>}/>,
				<ConnectorButton text={ConnectorButtonsEnum.CONTACT}
				                 onClick={() => window.open('https://t.me/mmpro_support1', '_blank')} icon={<PaymentIcon/>}/>,
				...prevButtons,
			]);
		}
	}, []);

	return (
		<UserStatusContext.Provider
			value={{isUserVerified, isUserSubmitted, isWalletVerified, isInvestmentAllowed, setIsWalletVerified}}>
			<StandardAppContainer
				headerNavigation={isWalletVerified ? navItems : []}
				connectorButtons={
					isWalletVerified ?
						buttons :
						[
							<ConnectorButton
								text={ConnectorButtonsEnum.VERIFY_WALLETS}
								url={RouteName.VERIFY_WALLETS}
								icon={<PaymentIcon/>}
							/>
						]
				}
				logoHref={'/'}
				locales={['en', 'ja']}
				isDarkBG={false}
				version={"1.0.2"}
			>
				<AppRouter/>
				<GradientCircles/>
			</StandardAppContainer>
		</UserStatusContext.Provider>
	);
};

