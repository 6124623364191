import type { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { useWeb3React, Web3ReactHooks, Web3ReactProvider } from '@web3-react/core'
import type { MetaMask } from '@web3-react/metamask'
import type { Network } from '@web3-react/network'
import type { WalletConnect } from '@web3-react/walletconnect'
import type { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import React from 'react'

import { hooks as metaMaskHooks, metaMask } from '../wallet/metaMask'
import { hooks as walletConnectV2Hooks, walletConnectV2 } from '../wallet/walletConnectV2'

const connectors: [MetaMask | WalletConnect | WalletConnectV2 | CoinbaseWallet | Network, Web3ReactHooks][] = [
	[metaMask, metaMaskHooks],
	[walletConnectV2, walletConnectV2Hooks],
]

interface ProviderProps {
	children: React.ReactNode
}

export default function Provider(props: ProviderProps) {
	const {children} = props
	return (
		<Web3ReactProvider connectors={connectors}>
			{children}
		</Web3ReactProvider>
	)
}
