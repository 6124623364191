export default {
  tileTitle: {
    en: "Identity information",ch: "身份資訊",
    ja: "身元情報"
  },

  nationalityLabel: {
    en: "Nationality",ch: "國籍",
    ja: "国籍"
  },
  firstNameLabel: {
    en: "First name",ch: "名字",
    ja: "ファーストネーム"
  },
  lastNameLabel: {
    en: "Last name",ch: "姓",
    ja: "苗字"
  },
  middleNameLabel: {
    en: "Middle name",ch: "中間名",
    ja: "ミドルネーム"
  },
  birthDateLabel: {
    en: "Birth Date",ch: "出生日期",
    ja: "生年月日"
  }
}
