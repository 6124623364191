import "./styles/tailwind.css";
import "./styles/index.css";
import './styles/StyleOverrides.scss'
import 'antd/dist/antd.css'
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {App} from "./App";
import './index.css'
import './fonts/Gilroy/Gilroy-Black.ttf'
import './fonts/Gilroy/Gilroy-BlackItalic.ttf'
import './fonts/Gilroy/Gilroy-Bold.ttf'
import './fonts/Gilroy/Gilroy-Extrabold.ttf'
import './fonts/Gilroy/Gilroy-BoldItalic.ttf'
import './fonts/Gilroy/Gilroy-ExtraboldItalic.ttf'
import './fonts/Gilroy/Gilroy-Heavy.ttf'
import './fonts/Gilroy/Gilroy-HeavyItalic.ttf'
import './fonts/Gilroy/Gilroy-Light.ttf'
import './fonts/Gilroy/Gilroy-Medium.ttf'
import './fonts/Gilroy/Gilroy-MediumItalic.ttf'
import './fonts/Gilroy/Gilroy-Regular.ttf'
import './fonts/Gilroy/Gilroy-RegularItalic.ttf'
import './fonts/Gilroy/Gilroy-Semibold.ttf'
import './fonts/Gilroy/Gilroy-SemiboldItalic.ttf'
import './fonts/Gilroy/Gilroy-Thin.ttf'
import './fonts/Gilroy/Gilroy-ThinItalic.ttf'
import './fonts/Gilroy/Gilroy-UltraLight.ttf'
import './fonts/Gilroy/Gilroy-UltraLightItalic.ttf'
import Provider from "./provider";
import {TonConnectUIProvider} from "@tonconnect/ui-react";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <TonConnectUIProvider manifestUrl={'https://compliance.mmprotrust.com//tonconnect-manifest.json'}>
      <Provider>
        <App/>
      </Provider>
    </TonConnectUIProvider>
  </BrowserRouter>,
  rootElement
);
