export default {
  manage: {
    en: "Manage",
    ch: "管理",
    ja: "管理"
  },
  noActiveDeals: {
    en: "There is no active deals.",
    ch: "沒有有效的交易。",
    ja: "アクティブな取引はありません。"
  },
  noClosedDeals: {
    en: "There is no closed deals.",
    ch: "沒有已關閉的交易。",
    ja: "クローズされた取引はありません。"
  },
  active: {
    en: "Active",
    ch: "積極",
    ja: "能動"
  },
  closed: {
    en: "Closed",
    ch: "閉",
    ja: "クローズド"
  },
  invested: {
    en: "Invested",
    ja: "投資",
    ch: "投資"
  },
  status: {
    en: "Status",
    ja: "地位",
    ch: "地位"
  }
}
