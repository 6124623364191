export default {
	confirmed: {
		en: "Deal confirmed!",
		ja: "取引が確定しました!",
		ch: "交易確認！"
	},
	invested: {
		en: "Invested: $",
		ja: "投資額: $",
		ch: "投資： $"
	},
	sharesAmount: {
		en: "Shares Amount: ",
		ja: "株式の金額: "
	}
}
