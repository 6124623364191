export enum ConnectorButtonsEnum {
  VERIFY_WALLET = 'Verify wallet',
  ACCOUNT = 'Account',
  PERSONAL_DATA = 'Personal data',
  SECURITY_SETTINGS = 'Security settings',
  VERIFY_WALLETS = 'Verify wallets',
  LOGOUT = 'Logout',
  MANAGE = 'Manage',
  INVEST = 'Invest',
  CONTACT = 'Contact us'
}