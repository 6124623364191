export default {
  governmentIssued: {
    en: "Government-issued",ch: "政府發行",
    ja: "政府発行"
  },
  originalFullSize: {
    en: "Original full-size, unedited documents",ch: "原始全尺寸、未經編輯的文件",
    ja: "元のフルサイズの未編集のドキュメント"
  },
  background: {
    en: "Place documents against a singe-coloured background",ch: "將文件放在單色背景下",
    ja: "単色の背景に対してドキュメントを配置する"
  },
  readable: {
    en: "Readable, well-lit, colour images",ch: "可讀、光線充足的彩色圖像",
    ja: "読みやすく明るいカラー画像"
  },
  noBlackAndWhite: {
    en: "No black and white images",ch: "沒有黑白圖像",
    ja: "白黒画像はありません"
  },
  noEdited: {
    en: "No edited or expired documents",ch: "沒有編輯或過期的文件",
    ja: "編集済みまたは期限切れのドキュメントはありません"
  }
}
