import React, {useContext} from "react";
import './index.css'
import styled from "styled-components";
import Stepper from "Standard/components/Stepper";
import PaymentStatus from "../ManageStatuses/Payment";
import SignDocuments from "../ManageStatuses/SignDocuments";
import ConfirmKYC from "../ManageStatuses/ConfirmKYC";
import DepositFunds from "../ManageStatuses/DepositFunds";
import DealConfirmation from "../ManageStatuses/DealConfirmation";
import {JustifyStartColumn} from "Standard/styles/GlobalStyledComponents";
import {IDeal, IDealActions, IDealStepStatus, Step, StepsStages, StepStatusEnum} from "types/ManageStatus";
import Text from "Standard/components/Text";
import {localized} from "Standard/utils/localized";
import texts from './localization'
import LocaleContext from "Standard/LocaleContext";
import Contract from "../ManageStatuses/Contract";
import DocumentSigning from "../ManageStatuses/DocumentSigning";
import PaymentConfirmation from "../ManageStatuses/PaymentConfirmation";


type DealControllingPropType = {
	currentDeal: IDeal | undefined,
	nextStep: (body: { desiredInvestmentAmount?: number }) => void
}

const DealControllingDefaultProps = {}

const DealControllingWrapper = styled.div`
  width: 650px;
  height: max-content;
  background: #fff;
  box-shadow: 0 0 27px rgba(94, 103, 120, 0.1);
  border-radius: 16px;
  padding: 32px 18px;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

const DealControlling = (props: DealControllingPropType) => {
	const {locale} = useContext(LocaleContext)
	const {currentDeal, nextStep} = props

	const StepsArray: Step[] = [
		{
			stage: StepsStages.INITIAL,
			component: (status: IDealStepStatus, action: IDealActions, adminErrorMessage?: string) =>
				<PaymentStatus
					adminErrorMessage={adminErrorMessage}
					nextStep={nextStep}
					status={status}
					action={action}
					price={currentDeal?.desiredInvestmentAmount}
				/>
		},
		{
			stage: StepsStages.KYC,
			component: (status: IDealStepStatus, action: IDealActions) =>
				<ConfirmKYC
					nextStep={nextStep}
					status={status}
					action={action}
				/>
		},
		// {
		// 	stage: StepsStages.CONTRACT,
		// 	component: (status: IDealStepStatus, action: IDealActions) =>
		// 		<Contract
		// 			status={status}
		// 			action={action}
		// 			nextStep={nextStep}
		// 		/>
		// },
		// {
		// 	stage: StepsStages.DOCUMENTS_SIGNING,
		// 	component: (status: IDealStepStatus, action: IDealActions) =>
		// 		<DocumentSigning
		// 			status={status}
		// 			action={action}
		// 			nextStep={nextStep}
		// 		/>
		// },
		{
			stage: StepsStages.DOCUMENTS,
			component: (status: IDealStepStatus, action: IDealActions, adminErrorMessage?: string) =>
				<SignDocuments
					adminErrorMessage={adminErrorMessage}
					nextStep={nextStep}
					status={status}
					action={action}
				/>
		},
		{
			stage: StepsStages.DEPOSIT,
			component: (status: IDealStepStatus, action: IDealActions, adminErrorMessage?: string) =>
				<DepositFunds
					adminErrorMessage={adminErrorMessage}
					nextStep={nextStep}
					status={status}
					action={action}
					isPrePaid={currentDeal?.isPrePaid}
					price={currentDeal?.desiredInvestmentAmount}
				/>
		},
		// {
		// 	stage: StepsStages.PAYMENT_CONFIRMATION,
		// 	component: (status: IDealStepStatus, action: IDealActions) =>
		// 		<PaymentConfirmation
		// 			status={status}
		// 			action={action}
		// 		/>
		// },
		{
			stage: StepsStages.CLOSED,
			component: (status: IDealStepStatus, action: IDealActions) =>
				<DealConfirmation
					status={status}
					action={action}
				/>,
		},
	]

	return (
		<DealControllingWrapper>
			{currentDeal && currentDeal.stage === StepsStages.CLOSED ?
				<JustifyStartColumn>
					<Text fontWeight={500} fontSize={20}>{localized(texts.confirmed, locale)}</Text>
					{currentDeal?.fundsDeposited && <Text fontWeight={400}
                                                fontSize={16}>{localized(texts.invested, locale)}{currentDeal?.fundsDeposited}</Text>}
					<Text fontWeight={400} fontSize={16}>{localized(texts.sharesAmount, locale)}{currentDeal?.sharesAmount}</Text>
					<Text fontWeight={400} fontSize={16}>Closed
						At: {currentDeal?.closedAt ? currentDeal?.closedAt.toString().replace("T", " ").slice(0, -5) : ''}</Text>
				</JustifyStartColumn>
				:
				<Stepper>
					{currentDeal && StepsArray.map((step, index) => {
						const currentStatusIndex = StepsArray.findIndex(step => step.stage === currentDeal.stage)
						let status: IDealStepStatus
						if (index < currentStatusIndex) {
						  status = StepStatusEnum.READY
						} else if (index > currentStatusIndex) {
						  status = StepStatusEnum.WAIT
						} else {
						  status = StepStatusEnum.ACTIVE
						}
						return <div
							key={step.stage}>{step.component(status, currentDeal.status, currentDeal?.adminErrorMessage)}</div>
					})}
				</Stepper>
			}
		</DealControllingWrapper>
	)
};

DealControlling.defaultProps = DealControllingDefaultProps

export default DealControlling
