import {RouteName} from "../router";
import {useContext, useState} from "react";
import NotificationContext from "../Standard/utils/NotificationContext";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import LocaleContext from "../Standard/LocaleContext";
import {localized} from "../Standard/utils/localized";
import texts from "./localization"

export const useSendCode = (body: {}, serverUrl: string, appUrl?: string) => {
  const [incorrectCodeError, setIncorrectCodeError] = useState('')
  const notification = useContext(NotificationContext)
  const history = useNavigate()
  const {locale} = useContext(LocaleContext);


  const [cookies, setCookie, removeCookie] = useCookies(['auth'])

  const requestOptions = {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(body)
  };

  const fetchCode = async () => {
    fetch(serverUrl, requestOptions)
      .then(res => res.json())
      .then(json => {
        if (json.statusCode === 200) {

          if (json.token && appUrl === RouteName.VERIFICATION) {
            setCookie("auth", `${json.token}`, {path: '/'});
            history(RouteName.VERIFICATION);
          }

          if (appUrl === RouteName.LOGIN) {
            history(appUrl)
            removeCookie('auth')
          }

          if (appUrl === RouteName.ACCOUNT) {
            notification.displayNotification(
              localized(texts['success'],locale),
              localized(texts['youSuccessfullyUpdateYourPersonalData'],locale),
            )
            history(RouteName.ACCOUNT)
          }

        } else {
          setIncorrectCodeError(json.message)
        }
      })
  }

  return {fetchCode, incorrectCodeError}
}