export default {
  success: {
    en: "Success",
    ch: "成功",
    ja: "成功",
  },
  youSuccessfullyUpdateYourPersonalData: {
    en: "You successfully updated your personal data",
    ch: "您已成功發送報告消息",
    ja: "レポート メッセージを正常に送信しました",
  }
}