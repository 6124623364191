export default {
	sign: {
		en: "Sign necessary documents",
		ja: "必要書類への署名",
		ch: "簽署必要的檔"
	},
	fromManager: {
		en: "Message from manager:",
		ja: "マネージャーからのメッセージ:",
		ch: "經理寄語："
	},
	getDocuments: {
		en: "Get documents",
		ja: "ドキュメントを取得する",
		ch: "獲取文件"
	},
	sentDocuments: {
		en: "We sent the documents to your email after signing the manager will check them.",
		ja: "署名後、ドキュメントをメールに送信し、マネージャーがチェックします。",
		ch: "我們在簽署後將檔發送到您的電子郵件，經理將對其進行檢查。"
	}
}