import React from 'react';

// Import colors from constants or use currentColor
// If icon uses more than one adjustable color you can pass colors from props
// BUT you must always provide default values for color props

export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99996 0.5C14.4933 0.5 18.2316 3.73333 19.0158 8C18.2325 12.2667 14.4933 15.5 9.99996 15.5C5.50663 15.5 1.7683 12.2667 0.984131 8C1.76746 3.73333 5.50663 0.5 9.99996 0.5ZM9.99996 13.8333C11.6995 13.833 13.3486 13.2557 14.6773 12.196C16.0061 11.1363 16.9357 9.65689 17.3141 8C16.9343 6.34442 16.0041 4.86667 14.6755 3.80835C13.3469 2.75004 11.6986 2.17377 9.99996 2.17377C8.30138 2.17377 6.65304 2.75004 5.32444 3.80835C3.99585 4.86667 3.0656 6.34442 2.6858 8C3.06421 9.65689 3.99386 11.1363 5.32258 12.196C6.65131 13.2557 8.30041 13.833 9.99996 13.8333V13.8333ZM9.99996 11.75C9.0054 11.75 8.05158 11.3549 7.34831 10.6516C6.64505 9.94839 6.24996 8.99456 6.24996 8C6.24996 7.00544 6.64505 6.05161 7.34831 5.34835C8.05158 4.64509 9.0054 4.25 9.99996 4.25C10.9945 4.25 11.9484 4.64509 12.6516 5.34835C13.3549 6.05161 13.75 7.00544 13.75 8C13.75 8.99456 13.3549 9.94839 12.6516 10.6516C11.9484 11.3549 10.9945 11.75 9.99996 11.75ZM9.99996 10.0833C10.5525 10.0833 11.0824 9.86384 11.4731 9.47314C11.8638 9.08244 12.0833 8.55253 12.0833 8C12.0833 7.44747 11.8638 6.91756 11.4731 6.52686C11.0824 6.13616 10.5525 5.91667 9.99996 5.91667C9.44743 5.91667 8.91753 6.13616 8.52683 6.52686C8.13612 6.91756 7.91663 7.44747 7.91663 8C7.91663 8.55253 8.13612 9.08244 8.52683 9.47314C8.91753 9.86384 9.44743 10.0833 9.99996 10.0833Z"
        fill="#D2D5DA"/>
    </svg>
  );
};
