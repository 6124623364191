import React, {useContext, useState} from "react";
import texts from './localization'
import LocaleContext from "Standard/LocaleContext";
import {localized} from "Standard/utils/localized";
import './index.css'
import styled from "styled-components";
import {FieldStatus} from "types/UserData";
import {useCookies} from "react-cookie";
import {API_URL} from "api/constants";
import Text from "Standard/components/Text";
import KycModal from "../KycModal";
import Modal from "Standard/components/Modal";
import TrustButton from "Standard/components/TrustButton";

type UserCardPropType = {
  firstName: any,
  lastName: any,
  middleName: any,
  nationality: any,
  birthdate: any,
  residence: any,
  region: any,
  city: any,
  address: any
}

const UserCardDefaultProps = {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 100;
  width: max-content;
  height: max-content;
  box-shadow: 0 0 27px rgba(94, 103, 120, 0.1);
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  margin-top: 30px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const UserImage = styled.img`
  width: 170px;
  height: 170px;
  border-radius: 20px;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px; 
  }
`

const UserImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`

const UserWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 50px;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
    gap: 25px;
  }
`

const FieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
`

const Line = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  background: rgba(24, 24, 51, .3);
`

const ButtonWrapper = styled.div`
  width: 220px;
  margin-top: 20px;
`

const TitleText = styled.p`
  margin: 0px;
  font-weight: 700;
  font-size: 32px;
  line-height: 127%;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`

const UserCard = (props: UserCardPropType) => {
  const {locale} = useContext(LocaleContext)
  const {firstName, lastName, middleName, city, region, nationality, address, residence, birthdate} = props

  const [cookies] = useCookies(['auth'])

  const [isModalActive, setIsModalActive] = useState(false)

  return (
   <Container>
     {/* <Text fontWeight={700} fontSize={32}>Your personal data</Text> */}
     <TitleText>Your personal data</TitleText>
     <CardWrapper>
       <UserWrapper>
         <UserImagesWrapper>
           <UserImage src={`${API_URL}/api/images/main/${cookies.auth}?${new Date().getTime()}`} alt=""/>
           <UserImage src={`${API_URL}/api/images/additional/${cookies.auth}?${new Date().getTime()}`} alt=""/>
         </UserImagesWrapper>
         <UserInfoWrapper>
           <FieldWrapper>
             <Text fontWeight={700} fontSize={20}>{lastName?.value}</Text>
             <Text fontWeight={700} fontSize={20}>{firstName?.value}</Text>
             <Text fontWeight={700} fontSize={20}>{middleName?.value}</Text>
           </FieldWrapper>
           <FieldWrapper>
             <Text fontWeight={600} fontSize={16}>{`${localized(texts['nationalityLabel'], locale)}:`}</Text>
             <Text fontWeight={400} fontSize={16}>{nationality?.value}</Text>
           </FieldWrapper>
           <FieldWrapper>
             <Text fontWeight={600} fontSize={16}>{`${localized(texts['birthDateLabel'], locale)}:`}</Text>
             <Text fontWeight={400} fontSize={16}>{birthdate?.value}</Text>
           </FieldWrapper>
           <FieldWrapper>
             <Text fontWeight={600} fontSize={16}>{`${localized(texts['residenceLabel'], locale)}:`}</Text>
             <Text fontWeight={400} fontSize={16}>{residence?.value}</Text>
           </FieldWrapper>
           <FieldWrapper>
             <Text fontWeight={600} fontSize={16}>{`${localized(texts['cityLabel'], locale)}:`}</Text>
             <Text fontWeight={400} fontSize={16}>{city?.value}</Text>
           </FieldWrapper>
           <FieldWrapper>
             <Text fontWeight={600} fontSize={16}>{`${localized(texts['mainAddressLabel'], locale)}:`}</Text>
             <Text fontWeight={400} fontSize={16}>{address?.value}</Text>
           </FieldWrapper>
         </UserInfoWrapper>
       </UserWrapper>
       <Line/>
       <Text fontWeight={400} fontSize={14}>{localized(texts['ifYourPersonalDataChangesPleaseUpdateItHere'], locale)}</Text>
       <ButtonWrapper>
         <TrustButton
           style={'green'}
           isValid
           onClick={() => setIsModalActive(true)}
         >
           {localized(texts.change,locale)}
         </TrustButton>
       </ButtonWrapper>
       {
         isModalActive &&
         <Modal onClose={() => setIsModalActive(false)}>
           <KycModal onSubmit={() => setIsModalActive(false)}/>
         </Modal>
       }
     </CardWrapper>
   </Container>
  )
};

UserCard.defaultProps = UserCardDefaultProps

export default UserCard