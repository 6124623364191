import React from 'react';

// Import colors from constants or use currentColor
// If icon uses more than one adjustable color you can pass colors from props
// BUT you must always provide default values for color props

export default () => {
  return (
    <svg width="65" height="46" viewBox="0 0 65 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="65" height="40" rx="3" fill="#F3F5F8"/>
      <rect x="8" y="4" width="50" height="30" rx="2" fill="url(#paint0_linear_263_148)"/>
      <rect x="11" y="13" width="4" height="2" fill="#5081BC"/>
      <rect x="17" y="13" width="8" height="2" fill="#6C6C6C"/>
      <rect x="11" y="17" width="4" height="2" fill="#5081BC"/>
      <rect x="17" y="17" width="14" height="2" fill="#6C6C6C"/>
      <rect x="11" y="21" width="4" height="2" fill="#5081BC"/>
      <rect x="17" y="21" width="14" height="2" fill="#6C6C6C"/>
      <rect x="11" y="27" width="10" height="2" fill="#5081BC"/>
      <rect x="23" y="27" width="16" height="2" fill="#6C6C6C"/>
      <path d="M47.0002 16.25C46.0835 16.25 45.3335 15.9583 44.7502 15.375C44.1668 14.7917 43.8752 14.0417 43.8752 13.125C43.8752 12.2083 44.1668 11.4583 44.7502 10.875C45.3335 10.2917 46.0835 10 47.0002 10C47.9168 10 48.6668 10.2917 49.2502 10.875C49.8335 11.4583 50.1252 12.2083 50.1252 13.125C50.1252 14.0417 49.8335 14.7917 49.2502 15.375C48.6668 15.9583 47.9168 16.25 47.0002 16.25ZM40.3335 22.9375V20.9792C40.3335 20.4514 40.4654 20 40.7293 19.625C40.9932 19.25 41.3335 18.9653 41.7502 18.7708C42.6807 18.3542 43.5731 18.0417 44.4272 17.8333C45.2814 17.625 46.1391 17.5208 47.0002 17.5208C47.8613 17.5208 48.7154 17.6285 49.5627 17.8438C50.4099 18.059 51.2988 18.3681 52.2293 18.7708C52.6599 18.9653 53.0071 19.25 53.271 19.625C53.5349 20 53.6668 20.4514 53.6668 20.9792V22.9375H40.3335Z" fill="#4B4B4B"/>
      <path d="M30.6831 41.0333L35.3998 36.3166L34.6331 35.5666L30.6831 39.5166L28.6831 37.5166L27.9331 38.2666L30.6831 41.0333ZM31.6664 44.6666C30.7553 44.6666 29.8942 44.4916 29.0831 44.1416C28.272 43.7916 27.5636 43.3138 26.9581 42.7083C26.3525 42.1027 25.8748 41.3944 25.5248 40.5833C25.1748 39.7721 24.9998 38.911 24.9998 37.9999C24.9998 37.0777 25.1748 36.211 25.5248 35.3999C25.8748 34.5888 26.3525 33.8833 26.9581 33.2833C27.5636 32.6833 28.272 32.2083 29.0831 31.8583C29.8942 31.5083 30.7553 31.3333 31.6664 31.3333C32.5886 31.3333 33.4553 31.5083 34.2664 31.8583C35.0775 32.2083 35.7831 32.6833 36.3831 33.2833C36.9831 33.8833 37.4581 34.5888 37.8081 35.3999C38.1581 36.211 38.3331 37.0777 38.3331 37.9999C38.3331 38.911 38.1581 39.7721 37.8081 40.5833C37.4581 41.3944 36.9831 42.1027 36.3831 42.7083C35.7831 43.3138 35.0775 43.7916 34.2664 44.1416C33.4553 44.4916 32.5886 44.6666 31.6664 44.6666Z" fill="#6CB15C"/>
      <defs>
        <linearGradient id="paint0_linear_263_148" x1="8" y1="5" x2="58" y2="34" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B4DDE7"/>
          <stop offset="0.489583" stopColor="#F3E0F4"/>
          <stop offset="1" stopColor="#B4DDE7"/>
        </linearGradient>
      </defs>
    </svg>
  );
};
