import React from 'react';

export default () => {
  return (
      <svg width="194" height="42" viewBox="0 0 349 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_501_24)">
              <path d="M217.32 0.169922H248.365V8.12531H236.972V42.3576H228.653V8.12531H217.32V0.169922Z" fill="#33CC66"/>
              <path d="M217.32 0.169922H248.365V8.12531H236.972V42.3576H228.653V8.12531H217.32V0.169922Z" fill="url(#paint0_linear_501_24)"/>
              <path d="M256.597 12.2228V17.4058C257.28 15.5174 258.445 14.0911 260.093 13.1268C261.741 12.1223 263.629 11.6201 265.759 11.6201V20.2987C263.388 19.9772 261.258 20.4594 259.369 21.7451C257.521 22.9906 256.597 25.0599 256.597 27.9527V42.3568H248.82V12.2228H256.597Z" fill="#33CC66"/>
              <path d="M256.597 12.2228V17.4058C257.28 15.5174 258.445 14.0911 260.093 13.1268C261.741 12.1223 263.629 11.6201 265.759 11.6201V20.2987C263.388 19.9772 261.258 20.4594 259.369 21.7451C257.521 22.9906 256.597 25.0599 256.597 27.9527V42.3568H248.82V12.2228H256.597Z" fill="url(#paint1_linear_501_24)"/>
              <path d="M290.199 28.495V12.2227H297.976V42.3567H290.199V38.9817C288.351 41.7942 285.357 43.2004 281.218 43.2004C277.922 43.2004 275.19 42.0955 273.02 39.8857C270.89 37.6357 269.825 34.5821 269.825 30.725V12.2227H277.601V29.7607C277.601 31.7294 278.123 33.2562 279.168 34.341C280.253 35.4259 281.72 35.9683 283.569 35.9683C285.618 35.9683 287.226 35.3455 288.391 34.1C289.597 32.8142 290.199 30.9459 290.199 28.495Z" fill="#33CC66"/>
              <path d="M290.199 28.495V12.2227H297.976V42.3567H290.199V38.9817C288.351 41.7942 285.357 43.2004 281.218 43.2004C277.922 43.2004 275.19 42.0955 273.02 39.8857C270.89 37.6357 269.825 34.5821 269.825 30.725V12.2227H277.601V29.7607C277.601 31.7294 278.123 33.2562 279.168 34.341C280.253 35.4259 281.72 35.9683 283.569 35.9683C285.618 35.9683 287.226 35.3455 288.391 34.1C289.597 32.8142 290.199 30.9459 290.199 28.495Z" fill="url(#paint2_linear_501_24)"/>
              <path d="M312.036 20.7807C312.036 22.0664 313.864 23.1714 317.521 24.0955C318.807 24.3767 319.972 24.7182 321.017 25.12C322.062 25.4816 323.107 26.024 324.152 26.7473C325.237 27.4303 326.081 28.3544 326.684 29.5196C327.286 30.6848 327.588 32.0308 327.588 33.5575C327.588 36.6513 326.422 39.0419 324.092 40.7295C321.761 42.3768 318.887 43.2004 315.471 43.2004C309.283 43.2004 305.062 40.8098 302.812 36.0285L309.564 32.2317C310.488 34.8433 312.457 36.1491 315.471 36.1491C318.244 36.1491 319.631 35.2852 319.631 33.5575C319.631 32.2718 317.802 31.1669 314.145 30.2428C312.779 29.8812 311.613 29.5196 310.648 29.158C309.684 28.7964 308.64 28.274 307.514 27.591C306.389 26.8678 305.525 25.9638 304.922 24.8789C304.36 23.7539 304.079 22.4481 304.079 20.9615C304.079 17.9883 305.164 15.6579 307.334 13.9704C309.544 12.2427 312.277 11.3789 315.532 11.3789C317.983 11.3789 320.214 11.9414 322.223 13.0664C324.232 14.1512 325.819 15.7182 326.984 17.7673L320.354 21.3834C319.39 19.3343 317.782 18.3097 315.532 18.3097C314.527 18.3097 313.683 18.5508 313 19.0329C312.357 19.4749 312.036 20.0575 312.036 20.7807Z" fill="#33CC66"/>
              <path d="M312.036 20.7807C312.036 22.0664 313.864 23.1714 317.521 24.0955C318.807 24.3767 319.972 24.7182 321.017 25.12C322.062 25.4816 323.107 26.024 324.152 26.7473C325.237 27.4303 326.081 28.3544 326.684 29.5196C327.286 30.6848 327.588 32.0308 327.588 33.5575C327.588 36.6513 326.422 39.0419 324.092 40.7295C321.761 42.3768 318.887 43.2004 315.471 43.2004C309.283 43.2004 305.062 40.8098 302.812 36.0285L309.564 32.2317C310.488 34.8433 312.457 36.1491 315.471 36.1491C318.244 36.1491 319.631 35.2852 319.631 33.5575C319.631 32.2718 317.802 31.1669 314.145 30.2428C312.779 29.8812 311.613 29.5196 310.648 29.158C309.684 28.7964 308.64 28.274 307.514 27.591C306.389 26.8678 305.525 25.9638 304.922 24.8789C304.36 23.7539 304.079 22.4481 304.079 20.9615C304.079 17.9883 305.164 15.6579 307.334 13.9704C309.544 12.2427 312.277 11.3789 315.532 11.3789C317.983 11.3789 320.214 11.9414 322.223 13.0664C324.232 14.1512 325.819 15.7182 326.984 17.7673L320.354 21.3834C319.39 19.3343 317.782 18.3097 315.532 18.3097C314.527 18.3097 313.683 18.5508 313 19.0329C312.357 19.4749 312.036 20.0575 312.036 20.7807Z" fill="url(#paint3_linear_501_24)"/>
              <path d="M348.946 12.2227V19.6959H342.134V32.2317C342.134 33.2763 342.395 34.0397 342.918 34.5218C343.44 35.004 344.204 35.2852 345.208 35.3656C346.213 35.4058 347.459 35.3857 348.946 35.3053V42.3567C343.681 42.9594 339.924 42.4772 337.673 40.9103C335.463 39.3031 334.358 36.4102 334.358 32.2317V19.6959H329.114V12.2227H334.358V6.13562L342.134 3.78516V12.2227H348.946Z" fill="#33CC66"/>
              <path d="M348.946 12.2227V19.6959H342.134V32.2317C342.134 33.2763 342.395 34.0397 342.918 34.5218C343.44 35.004 344.204 35.2852 345.208 35.3656C346.213 35.4058 347.459 35.3857 348.946 35.3053V42.3567C343.681 42.9594 339.924 42.4772 337.673 40.9103C335.463 39.3031 334.358 36.4102 334.358 32.2317V19.6959H329.114V12.2227H334.358V6.13562L342.134 3.78516V12.2227H348.946Z" fill="url(#paint4_linear_501_24)"/>
              <path d="M48.1952 41.7259H38.2528L37.4102 20.5315L24.9401 37.752H23.592L11.122 20.5315L9.94236 41.7259H0L2.3592 0.165039H5.22395L24.0976 25.0022L42.8027 0.165039H45.6674L48.1952 41.7259Z" fill="#181833"/>
              <path d="M103.154 41.7259H93.2113L92.3687 20.5315L79.8986 37.752H78.5505L66.2489 20.5315L65.0693 41.7259H55.127L57.4861 0.165039H60.3509L79.2245 25.0022L97.9296 0.165039H100.794L103.154 41.7259Z" fill="#181833"/>
              <path d="M139.763 12.253C139.763 16.7237 138.246 20.3665 135.044 23.1814C131.843 25.9963 127.293 27.4865 121.395 27.4865C119.878 27.4865 118.193 27.4865 116.171 27.3209V41.7265H110.441V0.331161C111.284 0.331161 113.475 0.165582 117.013 0.165582C120.552 6.25849e-07 122.911 0 124.091 0C134.539 0 139.931 3.97395 139.763 12.253ZM133.865 12.5842C133.865 9.7693 133.022 7.78232 131.506 6.62325C129.989 5.46418 127.293 4.80186 123.417 4.80186C122.911 4.80186 120.384 4.96744 116.171 5.13302V22.1879C118.362 22.3535 120.215 22.3535 121.732 22.3535C129.821 22.3535 133.865 19.0418 133.865 12.5842Z" fill="#181833"/>
              <path d="M162.64 12.3952L162.472 17.3626C159.944 17.0315 157.585 17.0315 155.563 17.197C154.215 17.3626 152.361 17.6938 150.17 18.5217V41.8686H144.609V15.7068C147.306 14.3822 149.833 13.3887 151.855 12.7263C153.878 12.2296 156.237 11.8984 158.596 11.8984C159.27 11.8984 160.618 12.064 162.64 12.3952Z" fill="#181833"/>
              <path d="M172.792 38.0832C169.759 35.2683 168.242 31.6255 168.242 27.1548C168.242 22.8497 169.759 19.2069 172.961 16.392C175.994 13.4116 179.87 12.0869 184.42 12.0869C189.138 12.0869 193.182 13.5771 196.047 16.392C199.08 19.2069 200.597 22.8497 200.597 27.3204C200.597 31.6255 199.08 35.2683 195.879 38.0832C192.677 41.0636 188.969 42.3883 184.42 42.3883C179.533 42.3883 175.657 40.898 172.792 38.0832ZM184.42 37.752C187.453 37.752 189.981 36.7585 192.003 34.7715C193.856 32.7846 194.867 30.3008 194.867 27.3204C194.867 24.3399 193.856 21.8562 192.003 19.8692C190.149 17.8822 187.621 16.8888 184.42 16.8888C181.218 16.8888 178.69 17.8822 176.836 19.8692C174.983 21.8562 173.972 24.3399 173.972 27.3204C173.972 30.3008 174.983 32.7846 176.836 34.7715C178.859 36.7585 181.386 37.752 184.42 37.752Z" fill="#181833"/>
          </g>
          <defs>
              <linearGradient id="paint0_linear_501_24" x1="348.946" y1="0.169922" x2="344.607" y2="53.5481" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#33CC9B"/>
                  <stop offset="1" stopColor="#33CC74"/>
              </linearGradient>
              <linearGradient id="paint1_linear_501_24" x1="348.946" y1="0.169151" x2="344.607" y2="53.5473" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#33CC9B"/>
                  <stop offset="1" stopColor="#33CC74"/>
              </linearGradient>
              <linearGradient id="paint2_linear_501_24" x1="348.946" y1="0.16899" x2="344.607" y2="53.5472" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#33CC9B"/>
                  <stop offset="1" stopColor="#33CC74"/>
              </linearGradient>
              <linearGradient id="paint3_linear_501_24" x1="348.946" y1="0.16904" x2="344.607" y2="53.5472" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#33CC9B"/>
                  <stop offset="1" stopColor="#33CC74"/>
              </linearGradient>
              <linearGradient id="paint4_linear_501_24" x1="348.946" y1="0.16907" x2="344.607" y2="53.5472" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#33CC9B"/>
                  <stop offset="1" stopColor="#33CC74"/>
              </linearGradient>
              <clipPath id="clip0_501_24">
                  <rect width="349" height="44" fill="white"/>
              </clipPath>
          </defs>
      </svg>

  );
};
