export default {
  "Invest": {
    en: "Invest",ch: "投資",
    ja: "投資"
  },
  "Manage": {
    en: "Manage",ch: "管理",
    ja: "管理"
  },
  "FAQ": {
    en: "FAQ",ch: "常見問題",
    ja: "よくある質問"
  }
}