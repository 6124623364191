export default {
  changePhoneNumber: {
    en: "Change phone number",
    ja: "電話番号を変更する",
    ch: "更改電話號碼"
  },
  newPhoneNumber: {
    en: "New phone number",
    ja: "新しい電話番号",
    ch: "新電話號碼"
  }
}
