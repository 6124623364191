import React, {useContext, useEffect, useState} from 'react';
import StepItem from "Standard/components/Stepper/StepItem";
import {AlignCenterRow, JustifyStartColumn,} from "Standard/styles/GlobalStyledComponents";
import Text from "Standard/components/Text";
import {API_URL} from "api/constants";
import {UserData} from "types/UserData";
import {useCookies} from "react-cookie";
import UserStatusContext from "context/UserStatusContext";
import TrustButton from "Standard/components/TrustButton";
import {useNavigate} from "react-router-dom";
import {RouteName} from "router";
import styled from "styled-components";
import {IDealActions, IDealStepStatus, StepStatusEnum, ActionStatusEnum} from "types/ManageStatus";
import {localized} from "../../../Standard/utils/localized";
import texts from './localization'
import LocaleContext from "../../../Standard/LocaleContext";

type SignDocumentsProps = {
  status: IDealStepStatus,
  action: IDealActions,
  nextStep: (body: { desiredInvestmentAmount?: number }) => void,
}

const ButtonWrapper = styled.div`
  width: 180px;
`

const ConfirmKYC = (props: SignDocumentsProps) => {
  const {status, action, nextStep} = props
  const {locale} = useContext(LocaleContext)

  const history = useNavigate()

  const {isUserVerified} = useContext(UserStatusContext)

  const [userData, setUserData] = useState<any>(undefined)

  const [cookies] = useCookies(['auth'])

  async function getUserData() {
    const userDataUrl = `${API_URL}/api/validation/data`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": cookies.auth
      }
    };

    fetch(userDataUrl, requestOptions)
      .then(res => res.json())
      .then(userData => {
        if (userData.statusCode === 200) {
          setUserData(userData)
        }
      })
      .catch((e) => console.log(''))
  }

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <StepItem status={status}>
      <JustifyStartColumn>
        <Text fontWeight={600} fontSize={24}>{localized(texts.confirm, locale)}</Text>
        <div className={'mb-2'}/>
        {status === StepStatusEnum.ACTIVE &&
          <>
            {action === ActionStatusEnum.USER_ACTION &&
              <>
                {isUserVerified ?
                  <JustifyStartColumn>
                    <Text fontWeight={500} fontSize={16}>{localized(texts.passing, locale)}</Text>
                    <div className={'mb-3'}/>
                    <ButtonWrapper>
                      <TrustButton style='green' onClick={() => nextStep({})} isValid>{localized(texts.nextStep, locale)}</TrustButton>
                    </ButtonWrapper>
                  </JustifyStartColumn>
                  :
                  <JustifyStartColumn>
                    <Text fontWeight={500} fontSize={16}>
                      {localized(texts.completeKYC, locale)}
                    </Text>
                    <div className={'mb-2'}/>
                    <ButtonWrapper>
                      <TrustButton style='green' onClick={() => history(RouteName.VERIFICATION)} isValid>{localized(texts.confirm, locale)}</TrustButton>
                    </ButtonWrapper>
                  </JustifyStartColumn>
                }
              </>
            }
            {action === ActionStatusEnum.ADMIN_ACTION &&
              <></>
            }
            {action === ActionStatusEnum.USER_ACTION_UNSUCCESSFUL &&
              <></>
            }
          </>
        }
      </JustifyStartColumn>
      <div className={'mb-4'}/>
    </StepItem>
  );
};

export default ConfirmKYC;
