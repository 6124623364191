import React from 'react';

// Import colors from constants or use currentColor
// If icon uses more than one adjustable color you can pass colors from props
// BUT you must always provide default values for color props

export default () => {
  return (
    <svg width="65" height="45" viewBox="0 0 65 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_263_205)">
        <rect width="65" height="40" rx="3" fill="#F3F5F8"/>
        <rect x="8" y="4" width="50" height="30" rx="2" fill="url(#paint0_linear_263_205)"/>
        <rect x="11" y="13" width="4" height="2" fill="#5081BC"/>
        <rect x="17" y="13" width="8" height="2" fill="#6C6C6C"/>
        <rect x="11" y="17" width="4" height="2" fill="#5081BC"/>
        <rect x="17" y="17" width="14" height="2" fill="#6C6C6C"/>
        <rect x="11" y="21" width="4" height="2" fill="#5081BC"/>
        <rect x="17" y="21" width="14" height="2" fill="#6C6C6C"/>
        <rect x="11" y="27" width="10" height="2" fill="#5081BC"/>
        <rect x="23" y="27" width="16" height="2" fill="#6C6C6C"/>
        <path d="M47.0002 16.25C46.0835 16.25 45.3335 15.9583 44.7502 15.375C44.1668 14.7917 43.8752 14.0417 43.8752 13.125C43.8752 12.2083 44.1668 11.4583 44.7502 10.875C45.3335 10.2917 46.0835 10 47.0002 10C47.9168 10 48.6668 10.2917 49.2502 10.875C49.8335 11.4583 50.1252 12.2083 50.1252 13.125C50.1252 14.0417 49.8335 14.7917 49.2502 15.375C48.6668 15.9583 47.9168 16.25 47.0002 16.25ZM40.3335 22.9375V20.9792C40.3335 20.4514 40.4654 20 40.7293 19.625C40.9932 19.25 41.3335 18.9653 41.7502 18.7708C42.6807 18.3542 43.5731 18.0417 44.4272 17.8333C45.2814 17.625 46.1391 17.5208 47.0002 17.5208C47.8613 17.5208 48.7154 17.6285 49.5627 17.8438C50.4099 18.059 51.2988 18.3681 52.2293 18.7708C52.6599 18.9653 53.0071 19.25 53.271 19.625C53.5349 20 53.6668 20.4514 53.6668 20.9792V22.9375H40.3335Z"
              fill="#4B4B4B"/>
        <g filter="url(#filter0_df_263_205)">
          <path d="M26 0L26.5562 16.2881L36.5801 3.43769L27.4562 16.942L43.119 12.4377L27.8 18L43.119 23.5623L27.4562 19.058L36.5801 32.5623L26.5562 19.7119L26 36L25.4438 19.7119L15.4199 32.5623L24.5438 19.058L8.88098 23.5623L24.2 18L8.88098 12.4377L24.5438 16.942L15.4199 3.43769L25.4438 16.2881L26 0Z"
                fill="white"/>
        </g>
        <g filter="url(#filter1_f_263_205)">
          <path d="M38.725 5.26942L27.4556 16.9415L42.0361 9.82487L27.7113 17.4432L43.7774 15.1805L27.7995 17.9994L43.7785 20.8122L27.7115 18.5557L42.0392 26.1685L27.456 19.0575L38.7299 30.7253L27.0578 19.4558L34.1744 34.0363L26.5561 19.7116L28.8188 35.7776L25.9999 19.7998L23.1871 35.7787L25.4436 19.7118L17.8308 34.0395L24.9418 19.4562L13.2741 30.7302L24.5435 19.0581L9.96298 26.1747L24.2877 18.5563L8.22168 20.819L24.1995 18.0001L8.2206 15.1874L24.2875 17.4439L9.95984 9.83105L24.5431 16.9421L13.2692 5.27432L24.9412 16.5438L17.8246 1.96325L25.443 16.288L23.1803 0.221941L25.9992 16.1998L28.8119 0.220858L26.5554 16.2878L34.1683 1.9601L27.0573 16.5434L38.725 5.26942Z"
                fill="#FEFFD8"/>
        </g>
        <g filter="url(#filter2_f_263_205)">
          <path d="M36.5555 3.42013L27.2703 16.7251L40.5443 7.39567L27.6021 17.1802L43.1093 12.4092L27.777 17.7155L43.9996 17.9701L27.7779 18.2787L43.1279 23.5338L27.6048 18.8145L40.5795 28.5559L27.2745 19.2707L36.604 32.5447L26.8195 19.6025L31.5904 35.1098L26.2842 19.7774L26.0296 36L25.721 19.7783L20.4658 35.1283L25.1851 19.6052L15.4437 32.5799L24.7289 19.2749L11.4549 28.6044L24.3972 18.8199L8.88988 23.5908L24.2222 18.2846L7.99964 18.03L24.2213 17.7214L8.87136 12.4662L24.3944 17.1855L11.4197 7.44416L24.7247 16.7294L15.3952 3.45536L25.1798 16.3976L20.4088 0.890301L25.7151 16.2227L25.9696 5.80439e-05L26.2782 16.2217L31.5334 0.871778L26.8141 16.3949L36.5555 3.42013Z"
                fill="white"/>
        </g>
        <path d="M29.5002 41.1999L32.0002 38.6999L34.5002 41.1999L35.2002 40.4999L32.7002 37.9999L35.2002 35.4999L34.5002 34.7999L32.0002 37.2999L29.5002 34.7999L28.8002 35.4999L31.3002 37.9999L28.8002 40.4999L29.5002 41.1999ZM32.0002 44.6666C31.0891 44.6666 30.2279 44.4916 29.4168 44.1416C28.6057 43.7916 27.8974 43.3138 27.2918 42.7083C26.6863 42.1027 26.2085 41.3944 25.8585 40.5833C25.5085 39.7721 25.3335 38.911 25.3335 37.9999C25.3335 37.0777 25.5085 36.211 25.8585 35.3999C26.2085 34.5888 26.6863 33.8833 27.2918 33.2833C27.8974 32.6833 28.6057 32.2083 29.4168 31.8583C30.2279 31.5083 31.0891 31.3333 32.0002 31.3333C32.9224 31.3333 33.7891 31.5083 34.6002 31.8583C35.4113 32.2083 36.1168 32.6833 36.7168 33.2833C37.3168 33.8833 37.7918 34.5888 38.1418 35.3999C38.4918 36.211 38.6668 37.0777 38.6668 37.9999C38.6668 38.911 38.4918 39.7721 38.1418 40.5833C37.7918 41.3944 37.3168 42.1027 36.7168 42.7083C36.1168 43.3138 35.4113 43.7916 34.6002 44.1416C33.7891 44.4916 32.9224 44.6666 32.0002 44.6666Z"
              fill="#C85348"/>
      </g>
      <defs>
        <filter id="filter0_df_263_205" x="4.88086" y="-2" width="42.2383" height="46" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_263_205"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_263_205" result="shape"/>
          <feGaussianBlur stdDeviation="1" result="effect2_foregroundBlur_263_205"/>
        </filter>
        <filter id="filter1_f_263_205" x="7.2207" y="-0.779053" width="37.5576" height="37.5579"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_263_205"/>
        </filter>
        <filter id="filter2_f_263_205" x="6.99951" y="-1" width="38" height="38" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_263_205"/>
        </filter>
        <linearGradient id="paint0_linear_263_205" x1="8" y1="5" x2="58" y2="34" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B4DDE7"/>
          <stop offset="0.489583" stopColor="#F3E0F4"/>
          <stop offset="1" stopColor="#B4DDE7"/>
        </linearGradient>
        <clipPath id="clip0_263_205">
          <rect width="65" height="45" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
