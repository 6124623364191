import React, {useContext, useEffect, useState} from "react";
import texts from './localization'
import LocaleContext from "Standard/LocaleContext";
import {localized} from "Standard/utils/localized";
import styled from 'styled-components';
import Text from 'components/Text'
import {JustifyStartColumn} from 'Standard/styles/GlobalStyledComponents'
import {useWeb3React} from "@web3-react/core";
import TrustButton from 'Standard/components/TrustButton';
import Spinner from "Standard/components/Spinner";
import {HidingText} from "Standard/components/HidingText";
import {API_URL} from "api/constants";
import {useCookies} from "react-cookie";
import NoPageError from "Standard/components/404";
import {useWeb3} from "../../Standard/hooks/useCommonContracts";
import NotificationContext from "../../Standard/utils/NotificationContext";
import UserStatusContext from "../../context/UserStatusContext";
import truncate from "utils/truncate";
import {isMetaMaskInstalled} from '../../metamask';
import {walletConnectV2} from '../../wallet/walletConnectV2'
import {metaMask, hooks} from '../../wallet/metaMask'
import {TonConnectButton, useTonAddress, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import './index.css'
type PaymentMethodPropType = {}

const PaymentMethodDefaultProps = {}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 36px;
  width: 100%;
  height: calc(100vh - 80px);
`

const CardWrapper = styled(JustifyStartColumn)`
  position: relative;
  width: 100%; /* Заменили max-content на 100% */
  max-width: 460px; /* Добавили максимальную ширину */
  background: #FFFFFF;
  border: 1px solid rgba(24, 24, 51, 0.1);
  border-radius: 16px;
  padding: 32px;
  margin-top: 25px;
  z-index: 1;

  @media (max-width: 576px) {
    margin: 25px auto;
    padding: 28px;
  }
`

const CurrentWalletWrapper = styled.div`
  margin-top: 8px;
  cursor: pointer;
`

const ButtonWrapper = styled.div`
  width: 240px;
`

const WalletConnectorWithText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`

const WalletConnector = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`

const WalletItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  cursor: pointer;
`

const WalletImg = styled.img`
  width: 30px;
  height: 30px;
`
const VerifyWallets = (props: PaymentMethodPropType) => {
	const {locale} = useContext(LocaleContext)
	const userFriendlyAddress = useTonAddress();
	const {setIsWalletVerified, isWalletVerified} = useContext(UserStatusContext)
	const {displayNotification} = useContext(NotificationContext)
	const {account} = useWeb3React()
	const {useProvider} = hooks
	const provider = useProvider()
	const web3 = useWeb3()

	const [metaMaskInstalled, setMetaMaskInstalled] = useState<boolean>(false);

	const [cookies] = useCookies(['auth'])

	const [verifiedWallets, setVerifiedWallets] = useState([])

	const [isServerError, setIsServerError] = useState<boolean>(false)

	const [isCopyShowing, setIsCopyShowing] = useState(false)

	const [isUserAlreadyBought, setIsUserAlreadyBought] = useState<null | boolean>(null)
	const [isQuestionHidden, setIsQuestionHidden] = useState<boolean>(false)

	async function copyTextToClipboard(text: string) {
		setIsCopyShowing(true)
		setTimeout(() => {
			setIsCopyShowing(false)
		}, 1500)
		if ('clipboard' in navigator) {
			return await navigator.clipboard.writeText(text);
		} else {
			return document.execCommand('copy', true, text);
		}
	}

	const signVerifyWallet = async () => {
		if (provider && account) {
			try {
				const signature = await provider.getSigner(account).signMessage(`I own a wallet: ${account.toLowerCase()}`);
				if (signature != null) {
					const requestOptions = {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"Authorization": cookies.auth
						},
						body: JSON.stringify({
							signature: signature,
							signingWallet: account.toLowerCase()
						})
					};

					fetch(`${API_URL}/api/wallets/signature`, requestOptions)
						.then(res => res.json())
						.then(json => {
							if (json.statusCode === 200) {
								updateWalletsStatus()
								getVerifiedWallets()
								displayNotification(
									'Success',
									'Your wallet verified'
								)
							}
						})
						.catch(e => {
							console.log(e)
							displayNotification(
								'Verification Error',
								'Something went wrong'
							)
						})
				}
			} catch (error) {
				console.error("Ошибка при подписании:", error);
			}
		}
	}
	const getVerifiedWallets = async () => {
		const WALLETS_URL = `${API_URL}/api/wallets/token`

		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": cookies.auth
			},
		}

		fetch(WALLETS_URL, requestOptions)
			.then((res) => res.json())
			.then(res => setVerifiedWallets(res.data.result))
	}
	const updateWalletsStatus = async () => {
		const USER_STATUS = `${API_URL}/api/validation/token?wallet=${account}&skipWallet=${!isUserAlreadyBought}`;

		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": cookies.auth
			}
		}

		fetch(USER_STATUS, requestOptions)
			.then(res => res.json())
			.then(json => {
				console.log(json)
				setIsWalletVerified(json.walletIsVerified)
				setIsQuestionHidden(true)
			})
	}
	const onMetamaskClick = async () => {
		if (metaMaskInstalled) {
			await metaMask.activate()
				.then(res => console.log(res))
				.catch((e) => console.log(e))
			return
		}

		displayNotification(
			'Please install MetaMask',
			''
		)
	}
	const connectWalletConnect = async () => {
		try {
			await walletConnectV2.activate()
				.then(res => console.log(res))
				.catch((e) => console.log(e))
		} catch (error) {
			console.error('Error connecting with WalletConnect', error);
		}
	};

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const checkAndTruncate = (text: string) => windowWidth < 576 ? truncate(text) : text;

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		if (isWalletVerified) {
			setIsUserAlreadyBought(true)
			setIsQuestionHidden(true)
		}
	}, [isWalletVerified]);

	useEffect(() => {
		getVerifiedWallets()
	}, [])

	useEffect(() => {
		setMetaMaskInstalled(isMetaMaskInstalled());
	}, []);

	return (
		<>
			{isServerError && <NoPageError isServerError={isServerError}/>}
			{!isServerError &&
        <Container>
					{isWalletVerified === undefined ?
						<Spinner size={25} color={'#33CC66'}/>
						:
						<>
							<Text fontWeight={600} fontSize={40}>{localized(texts.title, locale)}</Text>
							{!isQuestionHidden &&
                <CardWrapper gap={30}>
                  <Text fontWeight={400} fontSize={16}>{localized(texts.doYouHaveSales, locale)}</Text>
                  <TrustButton isValid style={'green'} onClick={() => {
										setIsUserAlreadyBought(true)
										setIsQuestionHidden(true)
									}}>{localized(texts.yes, locale)}</TrustButton>
                  <TrustButton isValid style={'black'} onClick={() => {
										setIsUserAlreadyBought(false)
										updateWalletsStatus()
									}}>{localized(texts.no, locale)}</TrustButton>
                </CardWrapper>
							}
							{isUserAlreadyBought &&
                <CardWrapper gap={30}>
                  <JustifyStartColumn>
                    <Text fontWeight={500} fontSize={20}>{localized(texts.currentlyConnected, locale)}</Text>
                    <div className={'mb-2'}/>
										{account || userFriendlyAddress ?
											<CurrentWalletWrapper onClick={() => copyTextToClipboard(`${account || userFriendlyAddress}`)}>
												<HidingText
													defaultText={account ? `${checkAndTruncate(account)}` : userFriendlyAddress}
													hidingText={`${localized(texts.copied, locale)}!`}
													peekOut={isCopyShowing}
												/>
											</CurrentWalletWrapper>
											:
											<Text fontWeight={400} fontSize={16}>{localized(texts.walletIsNotConnected, locale)}</Text>
										}
                  </JustifyStartColumn>
                  <JustifyStartColumn>
                    <Text fontWeight={500} fontSize={20}>{localized(texts.verifiedWallets, locale)}</Text>
                    <div className={'mb-2'}/>
										{verifiedWallets && verifiedWallets.length ?
											<>
												{verifiedWallets && verifiedWallets.map((wallet: { address: string }) =>
													<div key={wallet.address}>
														<Text fontSize={16} fontWeight={400}>{checkAndTruncate(wallet.address)}</Text>
														<div className={'mb-2'}/>
													</div>
												)}
											</>
											:
											<Text fontWeight={400} fontSize={16}>No wallet verified</Text>
										}
                  </JustifyStartColumn>
                  <JustifyStartColumn>
										{account ?
											<ButtonWrapper>
												<TrustButton style={'green'} isValid={Boolean(account)} onClick={signVerifyWallet}>
													{localized(texts.verifyNewWallet, locale)}
												</TrustButton>
											</ButtonWrapper>
											:
											<JustifyStartColumn gap={20}>
												<WalletConnectorWithText>
													<Text fontWeight={500} fontSize={20}>Binance Smart Chain</Text>
													<WalletConnector>
														<WalletItem
															onClick={onMetamaskClick}
														>
															<WalletImg src={"/images/wallet/metamask.svg"}/>
															<Text fontWeight={400} fontSize={16}>Metamask</Text>
														</WalletItem>
														<WalletItem
															onClick={connectWalletConnect}
														>
															<WalletImg src={"/images/wallet/trustwallet.svg"}/>
															<Text fontWeight={400} fontSize={16}>Wallet connect</Text>
														</WalletItem>
													</WalletConnector>
												</WalletConnectorWithText>
												<WalletConnectorWithText>
													<Text fontWeight={500} fontSize={20}>TON</Text>
													<TonConnectButton  />
												</WalletConnectorWithText>
											</JustifyStartColumn>
										}
                  </JustifyStartColumn>
                </CardWrapper>
							}
						</>
					}
        </Container>
			}
		</>
	)
};

VerifyWallets.defaultProps = PaymentMethodDefaultProps

export default VerifyWallets
